import * as babylon from 'babylonjs';
import {
  ActivityOriginType,
  ActivityType,
  AssetType,
  AutoCorrectStatus,
  CarrierType,
  CaseAnalysisImagingType,
  CaseAnalysisStatus,
  CaseApproachType,
  CaseCancellationListType,
  CaseCancellationType,
  CaseCancelledReasonType,
  CaseHoldReasonType,
  CaseInvalidReasonType,
  CaseMissedReasonType,
  CaseReportErrorCodeType,
  CaseReportStatusType,
  CaseRiskAssessmentType,
  CaseSpineProfile,
  CaseSpineType,
  CaseStageType,
  CaseType,
  CloudDesignQueueType,
  CloudDesignStatus,
  ConfigurationValueType,
  CoronalGoalType,
  DaisySegmentationStage,
  DaisySegmentationStatus,
  DaisySegmentationType,
  DistributionProductionSubstageType,
  EndPlate,
  EventType,
  Form19ErrorCodeType,
  Form20ProductStatusType,
  Form20ProductType,
  HIPProductionSubstageType,
  ImplantCharacteristicType,
  ImplantDirection,
  ImplantOrientation,
  ImplantType,
  InterbodyScrewLengthTypes,
  LevelResult,
  LevelResultNotUsedReason,
  LevelSize,
  LevelType,
  LocationType,
  LordosisDifferenceRationaleType,
  ManufacturingProductionSubstageType,
  MeasurementType,
  MeasurementsVersionType,
  MissedGoalJustification,
  NavigationSystemType,
  NotificationOriginType,
  NotificationStatus,
  NotificationType,
  Operation,
  OrganizationType,
  PackagingProductionSubstageType,
  PartType,
  PathologyType,
  PatientContactCadenceType,
  PatientContactPreferenceType,
  Permission,
  PlanFeedbackType,
  PlanStatusType,
  PlanVersionType,
  Position,
  PositionDistanceType,
  PostOpAnalysisType,
  PriceLevelType,
  ProductionStageType,
  SagittalGoalType,
  SegmentationImagingAssessmentType,
  SegmentationSliceThicknessType,
  SegmentationType,
  Statement,
  StatementType,
  SterilizationProductionSubstageType,
  SurgeonPreferenceMeasurementType,
  SurgeonPreferencesErrorCodeType,
  TargetHeightMetricType,
  TaskActionType,
  TaskPriorityType,
  TaskStatusType,
  ThreadType,
  UserRoleType,
  UserStatusType,
  UserType,
  VendorTaskCategory,
  VertebralBody,
} from './enums';
import { CaseImagingLandmark } from './types';

export type AssetPositionsMetaDataType = {
  autoCorrectionInputs: any;
  measurements: IMeasurementPointValues;
  formValidation: AssetPositionsMetaDataFormValidationType;
};

export type AssetPositionsMetaDataFormValidationType = {
  [key in LevelType]: AssetPositionsMetaDataFormValidationFromType | null;
};

export type AssetPositionsMetaDataFormValidationFromType = {
  strict: AssetPositionsMetaDataFormValidationAttributeType;
  recommended: AssetPositionsMetaDataFormValidationAttributeType;
};

export type AssetPositionsMetaDataFormValidationAttributeType = {
  lordosisInSpec: boolean;
  coronalInSpec: boolean;
  anteriorHeightInSpec: boolean;
  posteriorHeightInSpec: boolean;
};

export interface AssetPositionsPoints {
  position: Position;
  name: VertebralBody;
  endPlate: EndPlate;
  point: Coordinate;
}

export interface AssetPositions {
  preop: AssetPositionsPreop;
  plan: AssetPositionsPlan;
  version: number;
  metadata?: AssetPositionsMetaDataType;
}

export interface AssetPositionsPreop {
  points: AssetPositionsPoints[];
  vertebrae: AssetPositionsVertebralBody[];
}

export interface AssetPositionsInterbodyCenterPlane {
  centroidPosition: Coordinate;
  anteriorPosition: Coordinate;
  posteriorPosition: Coordinate;
  patientLeftPosition: Coordinate;
  patientRightPosition: Coordinate;
}

export interface AssetPositionsInterbodySize {
  ml: number;
  ap: number;
  cageTaper: number;
  graftWindow: string;
}

export interface AssetPositionsPlan {
  vertebrae: AssetPositionsVertebralBody[];
  points: AssetPositionsPoints[];
}

export interface AssetPositionsVertebralBody {
  name: VertebralBody;
  position: Coordinate | null;
  rotation: Coordinate;
}

export interface Coordinate {
  x: number;
  y: number;
  z: number;
}

export interface ImplantPosition {
  eulerAngles: babylon.Vector3;
  centroid: babylon.Vector3;
}

export interface IDicomMetadata {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  gender?: string;
  mrn?: string;
  birthDate?: string | null;
  studyDate?: string | null;
  protocolName?: string;
  sliceThickness?: string;
  institutionName?: string;
  referringPhysician?: string;
  manufacturer?: string;
  gantryDetectorTilt?: string;
  pixelSpacing?: number;
  hasSliceThicknessError: boolean;
  hasStudyDateError: boolean;
}

export interface IMetadata {
  createdBy: number;
  createdAt: Date;
  updatedBy: number;
  updatedAt: Date;
  deletedAt?: Date;
  createdByUser?: IUser;
  updatedByUser?: IUser;
}

export interface IAsset extends IMetadata {
  assetId: number;
  assetType: AssetType;
  caseId: number;
  fileName: string;
  metadata: any;
  size: number;
  version: number;
  planId?: number;
  signedDownloadUrl?: string;
}

export interface IPostOpAnalysis extends IMetadata {
  postOpAnalysisId: number;
  analysisType: PostOpAnalysisType;
  caseId: number;
  lumbarCoronalAngulation: number;
  lumbarLordosis: number;
  segmentalLumbarLordosis: any;
  segmentalCoronalAngle: any;
  segmentalAnteriorHeight: any;
  segmentalPosteriorHeight: any;
  pelvicTilt: number;
  thoracicKyphosis: number;
  sagittalVerticalAxis: number;
  coronalBalance: number;
  angleToS1?: any;
}

export interface ICloudDesignQueue extends IMetadata {
  cloudDesignQueueId: number;
  caseId: number;
  planId: number;
  assetId: number;
  level: LevelType;

  levelSize?: LevelSize;
  part: PartType;
  name: string;
  status: CloudDesignStatus;
  log?: any;
  startedAt?: Date;
  finishedAt?: Date;
  minutesProcessing?: number;
  type: CloudDesignQueueType;
  revision: number;
}

export interface IDaisySegmentationQueue extends IMetadata {
  daisySegmentationQueueId: number;
  caseId: number;
  assetId: number;
  name: string;
  status: DaisySegmentationStatus;
  sacrumStatus: DaisySegmentationStatus;
  vertebraeStatus: DaisySegmentationStatus;
  log?: any;
  startedAt?: Date;
  finishedAt?: Date;
  minutesProcessing?: number;
  resourceId?: string;
  resourceStatus?: JSON;
  case?: {
    caseId: number;
    number: string;
    activities: {
      activityType: ActivityType;
      delta: string;
    }[];
  };
}

export interface IAutoCorrectQueue extends IMetadata {
  autoCorrectQueueId: number;
  caseId: number;
  planId: number;
  status: AutoCorrectStatus;
  log?: any;
  startedAt?: Date;
  finishedAt?: Date;
  minutesProcessing?: number;
}

export interface IActivity extends IMetadata {
  activityId: number;
  caseId: number;
  originId: number;
  originType: ActivityOriginType;
  activityType: ActivityType;
  delta: any;
}

export interface IExternalSyncData {
  sourceId: string;
  recordId: number;
  recordType: 'ORGANIZATION' | 'LOCATION';
}

export interface IImplantPartDetail extends IMetadata {
  implantPartDetailId: number;
  implantType: ImplantType;
  nTopAutoOp1AssetId?: number;
  nTopAutoOp2AssetId?: number;
  nTopManualOp1AssetId?: number;
  nTopManualOp2AssetId?: number;
  drawingDocumentId?: string;
  drawingVersionId: string;
  drawingVersionNumber: number;
  activeDate?: Date | string;
  drawingNumber?: string;
  onShapeRelease?: string;
  apMin?: number;
  apMax?: number;
  mlMin?: number;
  mlMax?: number;
  cageHeightMin?: number;
  cageHeightMax?: number;
  lordoticAngleMin?: number;
  lordoticAngleMax?: number;
  coronalAngleMin?: number;
  coronalAngleMax?: number;
  bulletAngleMin?: number;
  bulletAngleMax?: number;
  bulletHeightMin?: number;
  bulletHeightMax?: number;
  bulletThreadHeightMin?: number;
  bulletThreadHeightMax?: number;
  patientContactUpperHeightMin?: number;
  patientContactUpperHeightMax?: number;
  patientContactLowerHeightMin?: number;
  patientContactLowerHeightMax?: number;
  cageTaperMin?: number;
  cageTaperMax?: number;
  onShapeUrl?: string;
  form037DocumentId?: string;
  form037VersionId?: string;
  form037VersionNumber?: number;
}

export interface IOrganizationMetadata {
  heatTreatAvailable?: boolean;
}

export interface IOrganization extends IMetadata {
  organizationId: number;
  organizationType: OrganizationType;
  locations: ILocation[];
  priceLevels: IPriceLevel[];
  name: string;
  externalSyncData: IExternalSyncData;
  metadata: IOrganizationMetadata;
}

export interface IPriceLevel extends IMetadata {
  priceLevelId: number;
  organizationId: number;
  organization: IOrganization;
  priceLevelType: PriceLevelType;
  oneLevel: number;
  twoLevel: number;
  threeLevel: number;
  fourLevel: number;
  fiveLevel: number;
  startDate: string;
  endDate: string;
}

export interface ILocation extends IMetadata {
  locationId: number;
  organizationId: number;
  locationType: LocationType;
  description: string;
  organization: IOrganization;
  regionId?: number;
  territoryId?: number;
  region?: IRegion;
  territory?: ITerritory;
  locationUser?: ILocationUser[];
  externalSyncData: IExternalSyncData;
}

export interface IUser extends IMetadata {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  status: UserStatusType;
  role: UserRoleType;
  organizationId?: number;
  organization: IOrganization;
  userType?: UserType;
  permissions: Permission[];
  locationId?: number;
  location: ILocation;
  locations?: ILocation[];
  reportsToUser?: IUser;
  linkedOrganizationId?: number;
  linkedOrganization?: IOrganization;
  preferences?: ISurgeonPreferences;
  firstCaseId?: number;
  userRegionTerritories?: UserRegionTerritory[];
  locationUser?: ILocationUser[];
  associatedUserIds?: number[];
}

export interface IMeasurementPointValues {
  [key: string]: number;
}

export interface IPatient extends IMetadata {
  patientId: number;
  caseId: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  birthDate: Date | string;
  gender: string;
  mrn?: string;
  contactCadence?: PatientContactCadenceType;
  contactPreference?: PatientContactPreferenceType;
  email?: string;
  phoneNumber?: string;
  hasPermissionToContact?: boolean;
  hasPermissionToSchedule?: boolean;
  participantId?: string;
  patientRecord: IPatientRecord;
  uniquePatientId?: string;
}

export interface IPatientRecord extends IMetadata {
  patientRecordId?: number;
  patientId: number;
  preopMeasurements?: IMeasurementPointValues;
  metadata?: any;
  bmi?: number;
  odi?: number;
  boneDensity?: string;
  tScoreSpine?: number;
  tScoreHip?: number;
  vasBack?: number;
  vasLeg?: number;
  pelvicIncidence?: number;
  lumbarLordosis?: number;
  lumbarCoronalCobb?: number;
  sagittalVerticalAxis?: number;
  coronalBalance?: number;
  l4S1LordoticDistribution?: number;
  globalTilt?: number;
  pelvicTilt?: number;
  sacralSlope?: number;
  c7SagittalVerticalLine?: number;
  slopeOfLineOfSight?: number;
  crestHeight?: number;
  s1AnteriorPubisLength?: number;
  s1AnteriorPubisAngle?: number;
  biFemoralAxisLength?: number;
  biFemoralAxisMidpointS1EndplateMidpoint?: number;
  biIliacWidth?: number;
  posteriorIliacCrestWidth?: number;
  coronalTilt?: number;
  posteriorCrestShift?: number;
  hasPelvisHighPelvicIncidence?: boolean;
  hasPelvisHighCrest?: boolean;
  hasPelvisOblique?: boolean;
}

export interface IPlan extends IMetadata {
  planId: number;
  caseId: number;
  name: string;
  description?: string;
  isAppReady: boolean;
  isActive: boolean;
  status: PlanStatusType;
  approvedAt: Date | string | null;
  operations: IOperation[];
  plusLevelSize: number;
  assetPositions: AssetPositions;
  assets?: IAsset[];
  implants?: IPlanImplant[];
  version: PlanVersionType;
  planShippingLabels?: IPlanShippingLabel[];
  planKitBom?: IKitBom;
}

export type PlanImplantBulletType = {
  insertionSide: {
    angle: number;
    height: number;
  };
  threadedSide?: {
    angle: number;
    height: number;
  };
};

export type ImplantReferencePoints = {
  centroidPosition: Coordinate;
  anteriorPosition: Coordinate;
  posteriorPosition: Coordinate;
  patientLeftPosition: Coordinate;
  patientRightPosition: Coordinate;
};

export type NtopAlifReferencePoints = {
  positionCentroid: Coordinate;
  positionX: Coordinate;
  positionY: Coordinate;

  smoothTopAnteriorCentroid: Coordinate;
  smoothTopAnteriorX: Coordinate;
  smoothTopAnteriorY: Coordinate;
  smoothTopAnteriorGrid: number;
  smoothTopAnteriorIterations: number;

  smoothBottomAnteriorCentroid: Coordinate;
  smoothBottomAnteriorX: Coordinate;
  smoothBottomAnteriorY: Coordinate;
  smoothBottomAnteriorGrid: number;
  smoothBottomAnteriorIterations: number;

  insertionBulletTopCentroid: Coordinate;
  insertionBulletTopX: Coordinate;
  insertionBulletTopY: Coordinate;
  insertionBulletTopGrid: number;
  insertionBulletTopIterations: number;

  insertionBulletBottomCentroid: Coordinate;
  insertionBulletBottomX: Coordinate;
  insertionBulletBottomY: Coordinate;
  insertionBulletBottomGrid: number;
  insertionBulletBottomIterations: number;
};

export type NtopTlifReferencePoints = {
  positionCentroid: Coordinate;
  positionX: Coordinate;
  positionY: Coordinate;

  insertionBulletTopCentroid: Coordinate;
  insertionBulletTopX: Coordinate;
  insertionBulletTopY: Coordinate;
  insertionBulletTopGrid: number;
  insertionBulletTopIterations: number;

  insertionBulletBottomCentroid: Coordinate;
  insertionBulletBottomX: Coordinate;
  insertionBulletBottomY: Coordinate;
  insertionBulletBottomGrid: number;
  insertionBulletBottomIterations: number;
};

export type NtopLlifReferencePoints = {
  positionCentroid: Coordinate;
  positionX: Coordinate;
  positionY: Coordinate;

  insertionBulletTopCentroid: Coordinate;
  insertionBulletTopX: Coordinate;
  insertionBulletTopY: Coordinate;
  insertionBulletTopGrid: number;
  insertionBulletTopIterations: number;

  insertionBulletBottomCentroid: Coordinate;
  insertionBulletBottomX: Coordinate;
  insertionBulletBottomY: Coordinate;
  insertionBulletBottomGrid: number;
  insertionBulletBottomIterations: number;

  threadBulletTopCentroid: Coordinate;
  threadBulletTopX: Coordinate;
  threadBulletTopY: Coordinate;
  threadBulletTopGrid: number;
  threadBulletTopIterations: number;

  threadBulletBottomCentroid: Coordinate;
  threadBulletBottomX: Coordinate;
  threadBulletBottomY: Coordinate;
  threadBulletBottomGrid: number;
  threadBulletBottomIterations: number;
};

export interface IPlanImplant {
  planImplantId?: number;
  planId: number;
  implantPartDetailId?: number;
  level: LevelType;
  partType: PartType;
  bullet: PlanImplantBulletType;
  position: Coordinate;
  rotation: Coordinate;
  referencePoints:
    | ImplantReferencePoints
    | NtopAlifReferencePoints
    | NtopLlifReferencePoints
    | NtopTlifReferencePoints;
  screwLength?: number;
  cageTaper?: number;
  graftWindow?: string;
  obliqueThreadAngle?: number;
  cranialCaudalThreadAngle?: number;
  ap: number;
  ml: number;
  threadHeight: number;
  excludedImplantSizes?: string[];
  assets?: IAsset[];
  metadata?: string;
}

export interface IPlanExcludedImplantSizes {
  implantType: ImplantType;
  level: LevelType;
  partType: PartType;
  plusSizeExcluded: boolean;
  minusSizeExcluded: boolean;
}

export interface ICaseResult extends IMetadata {
  caseResultId: number;
  caseId: number;
  level: LevelType;
  part: PartType;
  size: LevelSize;
  result: LevelResult;
  notUsedReasons: LevelResultNotUsedReason[];
  exceptionReason: string;
  screwsUsed?: number | string;
  screwLengthUsed?: InterbodyScrewLengthTypes;
  screwLengthRight?: InterbodyScrewLengthTypes;
  screwLengthMiddle?: InterbodyScrewLengthTypes;
  screwLengthLeft?: InterbodyScrewLengthTypes;
}

export interface ICaseCancellation extends IMetadata {
  cancelledAt: Date | string;
  note: string;
  reason: CaseCancelledReasonType | CaseMissedReasonType | CaseInvalidReasonType;
  cancellationType: CaseCancellationType;
}

export interface IReportCaseUpcomingStudyDate {
  caseId: number;
  stage: CaseStageType;
  number: string;
  surgeryDate: Date;
}

export interface ICaseStudyDate {
  assetId: number;
  assetType: AssetType;
  daysOld: number;
  expiryDate: string;
  monthsUntilExpiry: number;
  studyDate: string;
  isExpiryDateSetManually?: boolean;
  manualExpiryReason?: string;
}

export interface ICase extends IMetadata {
  caseId: number;
  approach: CaseApproachType;
  spineType: CaseSpineType;
  spineProfile: CaseSpineProfile;
  caseType: CaseType;
  stage: CaseStageType;
  number: string;
  shortNumber: string | undefined;
  assignedId: number;
  assignedUser: IUser;
  fieldRepId: number;
  fieldRepUser: IUser;
  events: IEvent[];
  comments: IComment[];
  levels: ILevels;
  levelsMetadata: ILevelsMetadata;
  includeRodTemplates: boolean;
  receivedAt: Date | string;
  surgeryDate: Date | string;
  isSurgeryDateTentative: boolean;
  isXrayNotRequired: boolean;
  surgeonId: number;
  pco: string[];
  pso: string[];
  uiv: string;
  liv: string;
  surgeonUser: IUser;
  location: ILocation;
  patient: IPatient;
  assets: IAsset[];
  plans: IPlan[];
  studyDate: ICaseStudyDate;
  activities: IActivity[];
  caseResults: ICaseResult[];
  caseCancellation: ICaseCancellation;
  hasSixWeekImaging: boolean;
  hasSixWeekAnalysis: boolean;
  hasOneYearImaging: boolean;
  hasOneYearAnalysis: boolean;
  tasks: ITask[];
  postOpAnalysisSixWeeks: IPostOpAnalysis;
  proposedAt: Date | string;
  deliveredAt: Date | string;
  caseTags: ICaseTag[];
  settings: CaseSettings;
  excludedInstruments?: string[];
  caseHold: ICaseHold;
  caseShipping: ICaseShipping[];
  caseCancellations?: ICaseCancellation[];
  vendorAssignments?: IVendorAssignment[];
  caseLevels: ICaseLevel[];
  caseReport?: ICaseReport;
}

export interface IExportCase {
  number: string;
  caseType: CaseType;
  caseId: number;
  surgeryDate: string;
  receivedAt: string;
  hospitalDeliveryDate: string;
  isSurgeryDateTentative: Boolean;
  organization: IOrganization;
  regionTerritory: {
    region: string;
    territory: string;
  };
  surgeonUser?: IUser;
  assignedUser?: IUser;
  fieldRepUser?: IUser;
  stage: CaseStageType;
  patient: IPatient;
  levelsPlanned: number;
  levelsCompleted: number;
  levelsBilled: number;
  priceLevel: IPriceLevel;
  levels: ILevels;
  planRevisions: number;
  planFirstCompletedAt: string;
  planCompletedAt: string;
  planApprovedAt: string;
  dicomCtAsset?: IAsset;
  dicomXrayAsset?: IAsset;
  oldestDicomCtAsset?: IAsset;
  oldestDicomXrayAsset?: IAsset;
  lastEvent?: IEvent;
  caseCancellation?: ICaseCancellation;
  pinnedComment?: IComment;
  nearestExpiryAsset: {
    assetId: number;
    assetType: AssetType;
    studyDate: string;
    expiryDate: string;
  };
}

export interface IExportCaseDwh {
  dailySnapshotDate: string;
  number: string;
  caseType: CaseType;
  caseId: number;
  surgeryDate?: string;
  receivedAt: string;
  ageSinceReceived: number;
  hospitalDeliveryDate?: string;
  isSurgeryDateTentative?: boolean;
  organization?: string;
  locationId?: number;
  location?: string;
  region: string;
  territory: string;
  surgeonId?: number;
  surgeonUser?: string;
  assignedUser?: string;
  fieldRepUser?: string;
  stage: CaseStageType;
  patientGender?: string;
  patientBirthDate?: string;
  levelsPlanned?: number;
  levelsCompleted?: number;
  levelsBilled?: number;
  projectedRevenue?: number;
  actualRevenue?: number;
  priceLevelType?: string;
  priceOneLevel?: number;
  priceTwoLevel?: number;
  priceThreeLevel?: number;
  priceFourLevel?: number;
  priceFiveLevel?: number;
  L1_L2?: string;
  L2_L3?: string;
  L3_L4?: string;
  L4_L5?: string;
  L5_S1?: string;
  L5_L6?: string;
  L6_S1?: string;
  L4_S1?: string;
  planRevisions?: number;
  planFirstCompletedAt?: string;
  planCompletedAt?: string;
  planApprovedAt?: string;
  latestDicomCtAsset?: string;
  latestDicomXrayAsset?: string;
  oldestDicomCtAsset?: string;
  oldestDicomXrayAsset?: string;
  allImagesReceived?: string;
  nearestExpAssetType?: string;
  nearestExpStudyDate?: string;
  nearestExpExpiryDate?: string;
  lastEventType?: string;
  eventOccurredAt?: string;
  eventNote?: string;
  cancelledAt?: string;
  cancellationType?: string;
  cancelReason?: string;
  cancelNote?: string;
  imagingToFirstPlan?: number;
  firstPlanToApproval?: number;
  caseDelivered?: string;
  approvalToDelivery?: number;
  imagingToFirstPlanWorkingDays?: number;
  sixWeeksPostOpImaging?: string;
  sixMonthsPostOpImaging?: string;
}

export interface IComment extends IMetadata {
  commentId: number;
  parentCommentId?: number;
  caseId: number;
  assetId?: number;
  comment: string;
  isPinned: boolean;
  asset: IAsset;
}

export interface IEvent extends IMetadata {
  eventId: number;
  caseId: number;
  note?: string;
  occurredAt: string | Date;
  dueDate?: string | Date;
  description: string;
  trackingNumber?: string;
  eventType: EventType;
  carrierType?: CarrierType;
  purchaseOrderNumber?: string;
  assetId?: number;
  asset?: IAsset;
}

export interface ITask extends IMetadata {
  taskId: number;
  caseId: number;
  description: string;
  status: TaskStatusType;
  statusDescription?: string;
  actionType?: TaskActionType;
  dueDate?: Date;
  priority: TaskPriorityType;
  assignedId?: number;
  assignedUser?: IUser;
  taskCase: ICase;
}

export interface INotification extends IMetadata {
  notificationId: number;
  caseId?: number;
  notificationType: NotificationType;
  originId?: number;
  originType: NotificationOriginType;
  status: NotificationStatus;
  parameters?: any;
  scheduledAt?: Date;
  sentAt?: Date;
}

export interface IMeasure {
  measurementId?: number;
  body: VertebralBody;
  position: Position;
  endPlate: EndPlate;
  point: number[];
  source?: 'CYBORG' | 'DAISY' | 'AUTO';
}

export interface IOperation {
  body: VertebralBody;
  operation: Operation;
  value: number;
}

export interface IMeasurementPoint {
  [key: string]: number[];
}

export interface ILevels {
  [key: string]: string;
}

export interface ILevelsMetadata {
  [key: string]: {
    anteriorInstrumentation: boolean;
    otherVendor: string;
  };
}

export interface IForm20Product {
  sku: string;
  description: string;
  gs1DeviceId: string;
  type: Form20ProductType;
  status: Form20ProductStatusType;
  weight?: string;
}

export interface IForm17Product {
  sku: string;
  description: string;
  gs1DeviceId: string;
  type: Form20ProductType;
  status: Form20ProductStatusType;
  direction?: ImplantDirection;
  implantType?: ImplantType;
  level?: string;
  cageOrientation?: string;
  plusSize?: string;
  threadAngle?: string;
}

export interface IPlanShippingLabel {
  contentNumber: number;
  patientInitials: string;
  indexNumber: string;
  imagingDate: string;
  expirationDate: string;
  lotNumber: string;
  patientId: string;
  mrn: string;
  hospital: string;
  surgeonName: string;
  ref: string;
  gtin: string;
  productDescription: string;
  insertionSide: string;
  level: string;
  cageOrientation: string;
  plusSize: string;
  threadAngle: string;
}

export interface Form19Error {
  type: 'APPROVAL' | 'STEP_1' | 'STEP_4' | 'FORM_REVISION' | 'LEVEL_REVISION' | 'SCREW_LENGTH';
  code: Form19ErrorCodeType;
  characteristic?: ImplantCharacteristicType;
  levelType?: LevelType;
  partType?: PartType;
  size?: 'MINUS' | 'PLAN' | 'PLUS';
  value?: string | number;
  range?: [number, number];
}

export interface ITag extends IMetadata {
  tagId: number;
  label: string;
  tagCategoryId: number | null;
  tagCategory: ITagCategory | null;
}

export interface ITagCategory extends IMetadata {
  tagCategoryId: number;
  label: string;
  color: string | null;
}

export interface ICaseTag extends IMetadata {
  caseTagId: number;
  tagId: number;
  caseId: number;
  tag: ITag;
  case: ICase;
}

export type EmailPriorityType = 'high' | 'normal' | 'low' | undefined;

export type CaseSettings = {
  measurementsVersion: MeasurementsVersionType;
};

export enum DowntimeType {
  Holiday = 'HOLIDAY',
  Outage = 'OUTAGE',
}

export interface DowntimeDateRange {
  startDate: string;
  endDate: string;
  description: string;
  downtimeType: DowntimeType;
  vendorId: number | null;
}

export type HolidayDate = {
  year: number;
  month: number;
  day: number;
  description: string;
};

export type BusinessDate = {
  year: number;
  month: number;
  day: number;
  weekday: number;
};

export type LevelNotes = {
  level: LevelType;
  notes: string;
};

export type LevelRevision = {
  level: LevelType;
  revision: number;
  description?: string;
};

export type AlifXLevelScrewLengthType = {
  level: LevelType;
  screwLength?: InterbodyScrewLengthTypes;
};

export type EncodedAdditionalImageType = {
  levelType: LevelType;
  encodedAdditionalImage: string;
};

export type LevelAdditionalImages = {
  level: LevelType;
  additionalImage: any;
  encodedImage?: string | undefined;
  deletedAssetId?: number | undefined;
  assetType?: AssetType;
  asset?: IAsset;
};

export type Form19Angles = {
  level: LevelType | string;
  attribute: string;
  minus01: number | string | undefined;
  plan02: number | string | undefined;
  plus03: number | string | undefined;
};

export type Form19ManualAttributes = {
  level: LevelType | string;
  attribute: string;
  minus01: boolean | undefined;
  plan02: boolean | undefined;
  plus03: boolean | undefined;
};

export type Form19PartAttributes = {
  level: LevelType;
  attribute: string;
  minus01: number | undefined;
  plan02: number | undefined;
  plus03: number | undefined;
};

export type ProductionSubstageType =
  | ManufacturingProductionSubstageType
  | HIPProductionSubstageType
  | PackagingProductionSubstageType
  | SterilizationProductionSubstageType
  | DistributionProductionSubstageType;

export type ProductionStageEstimate = {
  order: number;
  days: number;
  vendorId: number | null;
  stage: ProductionStageType;
  subStage: ProductionSubstageType | null;
};

export interface ExportBlob {
  name: string;
  blob: Blob;
  assetType?: AssetType;
  size?: number;
}

export type ProductionStageComputedEstimate = {
  vendorId: number | null;
  startDate: string;
  endDate: string;
  lengthInDays: number;
  lengthInBusinessDays: number;
  vendorEstimateInBusinessDays: number;
} & Pick<ProductionStageEstimate, 'stage' | 'subStage' | 'order'>;

export type ProductionPipelineEstimate = {
  completedInDays: number;
  endDate: string;
  startDate: string;
  pipeline: ProductionStageComputedEstimate[];
};

export type AllowedImplantExclusionType = {
  implantType: ImplantType;
  excludedSize: LevelSize;
};

export interface ICaseImplantSpecification extends IMetadata {
  caseImplantSpecificationId: number;
  caseId: number;
  cloudDesignQueueId: number | null;
  level: LevelType;
  partType: PartType;
  size: LevelSize;
  apDepth: number;
  mlWidth: number;
  maxHeight: number;
  bulletHeight?: number;
  bulletAngle?: number;
  leftBulletHeight?: number;
  leftBulletAngle?: number;
  rightBulletHeight?: number;
  rightBulletAngle?: number;
  coronalAngle?: number;
  lordoticAngle?: number;
  isBulletMinHeightValid: boolean;
  isBulletMinDistanceValid: boolean;
  isCosmeticCheckValid: boolean;
  isPartMarkingsValid: boolean;
  screwLength?: InterbodyScrewLengthTypes;
  anteriorHeight?: number;
  posteriorHeight?: number;
  revision: number;
  description?: string;
}

export type DimensionFileDataType = {
  apDepth?: number;
  mlWidth?: number;
  maxHeight?: number;
  bulletHeight?: number;
  bulletAngle?: number;
  leftBulletHeight?: number;
  leftBulletAngle?: number;
  rightBulletHeight?: number;
  rightBulletAngle?: number;
};

export interface IProceduresRevenue {
  month: number | string;
  actualProcedures?: number;
  actualLevels?: number;
  scheduledProcedures?: number;
  scheduledLevels?: number;
  actualRevenue?: number;
  scheduledRevenue?: number;
}

export interface IOnShapeSettings {
  urls: {
    production: {
      ACDF: string;
      ACDF_X: string;
      ACDF_X_NO_CAM: string;
      ALIF: string;
      ALIF_X: string;
      LLIF_LEFT: string;
      LLIF_RIGHT: string;
      TLIF_C_LEFT: string;
      TLIF_C_RIGHT: string;
      TLIF_CA_LEFT: string;
      TLIF_CA_RIGHT: string;
      TLIF_O_LEFT: string;
      TLIF_O_RIGHT: string;
    };
  };
  m4: {
    urls: {
      PELVIS: string;
      ACDF: string;
      ACDF_X: string;
      ACDF_X_NO_CAM: string;
      ALIF: string;
      ALIF_X: string;
      LLIF: string;
      TLIF_C: string;
      TLIF_CA: string;
      TLIF_O: string;
    };
  };
  v3: {
    urls: {
      PELVIS: string;
      ACDF: string;
      ACDF_X: string;
      ACDF_X_NO_CAM: string;
      ALIF: string;
      ALIF_X: string;
      LLIF: string;
      TLIF_C: string;
      TLIF_CA: string;
      TLIF_O: string;
    };
  };
}

export interface IApplicationSettings {
  product?: {
    kitCartonStartDate?: Date | string | null;
    m4lStartDate?: Date | string | null;
    tlifCM4lStartDate?: Date | string | null;
    tlifCOrientationStartDate?: Date | string | null;
    alifXRotationLockStartDate?: Date | string | null;
    tlifCArticulatingStartDate?: Date | string | null;
    reusableInstrumentsStartDate?: Date | string | null;
  };
  operations?: {
    defaultVendors?: {
      additivePrinting?: number;
      finalShipment?: number;
    };
  };
  externalApps: {
    cyborgUrl: string;
    ventiUrl: string;
    designerReportUrl: string;
    autoXrUrl: string;
    autoPlanningUrl: string;
  };
  caseReports: {
    peerReviewUserIds: number[];
  };
  featureFlags: {
    angledInstrumentsEnabled: boolean;
    autoCorrectEnabled: boolean;
    cervicalSupportEnabled: boolean;
    cyborgV2Enabled: boolean;
    daisyV2Enabled: boolean;
    fastImplantCuttingEnabled: boolean;
    implantManagementEnabled: boolean;
    implantSizeExclusionEnabled: boolean;
    reusableInstrumentsEnabled: boolean;
    caseReportAutomationEnabled: boolean;
    nTopLumbarEnhancementsEnabled: boolean;
  };
}

export interface ICloudDesignSettings {
  [PartType.ALIF]: {
    url: string;
    release: string;
  };
  [PartType.ALIF_X_TWO_UP]: {
    url: string;
    release: string;
  };
  [PartType.LLIF_LEFT]: {
    url: string;
    release: string;
  };
  [PartType.LLIF_RIGHT]: {
    url: string;
    release: string;
  };
  [PartType.TLIFC_OFFSET_LEFT]: {
    url: string;
    release: string;
  };
  [PartType.TLIFC_OFFSET_RIGHT]: {
    url: string;
    release: string;
  };
  [PartType.TLIFO_LEFT]: {
    url: string;
    release: string;
  };
  [PartType.TLIFO_RIGHT]: {
    url: string;
    release: string;
  };
}

export interface ZipFileEntry {
  data: Buffer;
  name: string;
}

export interface ValidFile {
  filename: string;
  assetType: AssetType;
  data: Buffer;
}

export interface ICloudDesignMessageBody extends IQueueMessageBody {
  caseNumber?: string;
  archiveFileName?: string;
  srcFile: string;
  outputFile: string;
  minsSpent: number;
  status: CloudDesignStatus;
  output: string;
  nTopVersion: string;
  dirName?: string;
  ec2InstanceId?: string;
  currentLocation?: string;
  currentLocationFileListing?: string;
}

export interface IQueueMessageBody {
  key: string;
  id: number;
  environment: string;
}

export interface IDaisySegmentationQueueMessageBody extends IQueueMessageBody {
  caseNumber: string;
  status: DaisySegmentationStatus;
  stage: DaisySegmentationStage;
  segmentation: DaisySegmentationType;
  message: string;
}

export interface IExportPostOpCase {
  number: number;
  caseType: CaseType;
  spineProfile: CaseSpineProfile;
  caseId: number;
  surgeryDate: Date | string;
  receivedAt: Date;
  levels: ILevels;
  stage: CaseStageType;
  deletedAt: Date;
  isSurgeryDateTentative: Boolean;
  isXrayNotRequired: Boolean;
  updatedAt: Date;
  uiv: string;
  liv: string;
  deliveredAt: Date;
  proposedAt: Date;
  fieldRepUser: IUser;
  surgeonUser: IUser;
  organization: IOrganization;
  assignedUser: IUser;
  patient: IPatient;
  patientRecord: IPatientRecord;
  dicomCtAsset: IAsset;
  dicomXrayAsset: IAsset;
  caseCancellation: ICaseCancellation;
  tags: [ITag];
  caseResults: [ICaseResult];
  acceptedPlan: IPlan;
  postOpSixWeeksImaging: IAsset;
  postOpAnalysisSixWeeks: IPostOpAnalysis;
  measurementsVersion: MeasurementsVersionType;
}

export interface IRegion extends IMetadata {
  regionId: number;
  name: string;
  territories: ITerritory[];
}

export interface ITerritory extends IMetadata {
  territoryId: number;
  name: string;
  regionId: number;
}

export type UserRegionTerritory = {
  userRegionTerritoryId: number;
  userId: number;
  region: IRegion;
  territory?: ITerritory;
  territoryId: number;
};

export type UserRoleItemType = {
  key: string;
  type: 'ROLE' | 'USER';
  value: string;
};

export type EmailSettings = {
  draftCaseCreated: UserRoleItemType[];
  caseCreated: UserRoleItemType[];
  caseUpdated: UserRoleItemType[];
  taskUpdated: UserRoleItemType[];
  caseProposed: UserRoleItemType[];
  peerReview: UserRoleItemType[];
  qaReview: UserRoleItemType[];
  qaApproved: UserRoleItemType[];
  caseFinalRelease: UserRoleItemType[];
  caseCompleted: UserRoleItemType[];
  postOpAnalysisImagingUpdated: UserRoleItemType[];
  caseCancelled: UserRoleItemType[];
  surgeonCreated: UserRoleItemType[];
  surgeryDateChanged: UserRoleItemType[];
};

export type TesterEmailSettings = {
  whitelist: UserRoleItemType[];
};

export type ImplantDrawingDocumentType = {
  documentId: string;
  documentName?: string;
  versions: Array<{
    versionId: string;
    versionNumber: number;
  }>;
};

export type OtherPrintingDocumentType = {
  documentName: string;
  versionId: string;
  versionNumber: number;
};

export type ImplantDrawingSettings = {
  alif: ImplantDrawingDocumentType;
  llif: ImplantDrawingDocumentType;
  alifx: ImplantDrawingDocumentType;
  tlifc: ImplantDrawingDocumentType;
  tlifca?: ImplantDrawingDocumentType;
  tlifo: ImplantDrawingDocumentType;
  otherDocuments?: Array<OtherPrintingDocumentType>;
};

export type Form19Type = {
  alif: Form19JsonHeaderType;
  llif: Form19JsonHeaderType;
  alifx: Form19JsonHeaderType;
  tlifc: Form19JsonHeaderType;
  tlifo: Form19JsonHeaderType;
  from19: Form19DescriptionType;
  interbodyMeasurements: From19InterbodyMeasurementsType;
};

export type LevelForm19Type = {
  [key: string]: Form19JsonAttributesType;
};

export type From19InterbodyMeasurementsType = {
  attributes: From19InterbodyMeasurementsAttributesType;
};

export type From19InterbodyMeasurementsAttributesType = {
  coronalAngle: IForm19JsonAttributes;
  lordoticAngle: IForm19JsonAttributes;
  anteriorHeight: IForm19JsonAttributes;
  posteriorHeight: IForm19JsonAttributes;
  anteriorHeightPreop: IForm19JsonAttributes;
};

export type Form19DescriptionType = {
  wi: string;
  sop: string;
  name: string;
  version: string;
  attributes: any;
  documentDescription: string;
};

export type IForm19JsonAttributes = {
  max: number;
  min: number;
  fixedValue?: number;
};

export type IForm19JsonHeader = {
  version: string;
  drawingNumber: string;
};

export type IForm19JsonLevel = {
  header?: IForm19JsonHeader;
  attributes: IForm19JsonAttributes[];
};

export type Form19JsonHeaderType = {
  header?: IForm19JsonHeader;
  attributes: Form19JsonAttributesType;
};

export type Form19JsonAttributesType = {
  ap: IForm19JsonAttributes;
  ml: IForm19JsonAttributes;
  cageHeight: IForm19JsonAttributes;
  bulletAngle: IForm19JsonAttributes;
  bulletHeight: IForm19JsonAttributes;
  coronalAngle: IForm19JsonAttributes;
  lordoticAngle: IForm19JsonAttributes;
  bulletTaperHeight: IForm19JsonAttributes;
  bulletUpperHeight: IForm19JsonAttributes;
  patientContactLength: IForm19JsonAttributes;
  patientContactLowerHeight: IForm19JsonAttributes;
  patientContactUpperHeight: IForm19JsonAttributes;
};

export interface ImplantMeasurementRange {
  minAP: number;
  maxAP: number;
  minML: number;
  maxML: number;
  minCageTaper?: number;
  maxCageTaper?: number;
}

export interface IAxes {
  xAxis: babylon.Vector3;
  yAxis: babylon.Vector3;
  zAxis: babylon.Vector3;
}

export interface AssetPositionsInterbodyCenterPlane {
  centroidPosition: Coordinate;
  anteriorPosition: Coordinate;
  posteriorPosition: Coordinate;
  patientLeftPosition: Coordinate;
  patientRightPosition: Coordinate;
}

export interface MeasurementDefinition {
  type: MeasurementType;
  start: {
    body: VertebralBody;
    endPlate: EndPlate;
  };
  end: {
    body: VertebralBody;
    endPlate: EndPlate;
  };
}

export interface IKit {
  kitItems: IKitItem[];
  itemsCount: number;
  totalWeight: number;
}

export interface IKitItem {
  itemNumber: number;
  partNumber: string;
  description: string;
  itemType: Form20ProductType;
  weight?: string;
}

export interface IKitCarton {
  kitName: string;
  kitItems: IKitItem[];
  itemsCount: number;
  totalWeight: number;
}

export interface IKitBom {
  kitCartons: IKitCarton[];
  excludedItems: IKitItem[];
}

export interface ICaseHold extends IMetadata {
  reason: string;
  holdType: CaseHoldReasonType;
}

export interface JSONArray extends Array<JSONValue> {}

export type JSONValue = string | number | boolean | JSONObject | JSONArray;

export interface JSONObject {
  [x: string]: JSONValue | JSONArray;
}

export interface ICaseShipping {
  caseShippingId: number;
  caseId: number;
  source: string;
  status: string;
  trackingNumber: string;
  result: any;
  createdAt: Date;
}

export interface IShipmentTrackingLocation {
  city?: string;
  country?: string;
  object?: string;
  state?: string;
  zip?: string;
}

export interface IShipmentTrackingDetails {
  carrier_code?: string;
  datetime?: string;
  description?: string;
  message?: string;
  object?: string;
  source?: string;
  status?: string;
  status_detail?: string;
  tracking_location?: IShipmentTrackingLocation;
}

export interface ILocationUser extends IMetadata {
  locationUserId: number;
  userId: number;
  locationId: number;
  user: IUser;
  location: ILocation;
}

export interface ICaseCalendarQueryVariables {
  caseCancellationTypeFilter?: CaseCancellationListType[];
  caseTypeFilter: CaseType[];
  tagsFilter: number[];
  orderBy: {
    surgeryDate: string;
  };
  search: string;
  stageFilter: CaseStageType[];
  startDate: string;
  endDate: string;
  showTentativeSurgeryDates: boolean;
}

export interface ICaseCalendarFilteredCases {
  title: string;
  date: string;
  extendedProps: ICase;
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  className: string | string[];
}

export interface ISurgeonPreferences extends IMetadata {
  surgeonPreferenceId: number | null;
  userId: number;
  preferredProcedures: ImplantType[];
  sagittalGoalPrimary: SagittalGoalType;
  sagittalGoalSecondary?: SagittalGoalType;
  sagittalGoalOther?: string;
  coronalGoalPrimary?: CoronalGoalType;
  coronalGoalSecondary?: CoronalGoalType;
  navigationSystems: NavigationSystemType[];
  navigationSystemOther?: string;
  targetHeightMetric?: TargetHeightMetricType;
  excludedInstruments?: string[];
  reusableInstruments?: boolean | null;
  note?: string;
  surgeonPreferenceImplants: ISurgeonPreferenceImplant[];
  surgeonPreferenceImplantMeasurements: ISurgeonPreferenceImplantMeasurement[];
}

export interface ISurgeonPreferenceImplant {
  surgeonPreferenceImplantId: number | null;
  surgeonPreferenceId: number | null;
  implantType: ImplantType;
  positioning: PositionDistanceType;
  direction?: ImplantDirection | null;
  orientation?: ImplantOrientation | null;
  orientationByLevels?: ILevels;
  screwLength?: InterbodyScrewLengthTypes | null;
  maximizeFootprint?: boolean | null;
  plusSizeImplant: number;
  minimalHeightSpecification: boolean;
  excludedImplantSizes?: string[];
  note?: string;
}

export interface ISurgeonPreferenceImplantMeasurement {
  surgeonPreferenceImplantMeasurementId: number | null;
  surgeonPreferenceId: number | null;
  implantType: ImplantType;
  levelType?: LevelType;
  measurementType: SurgeonPreferenceMeasurementType;
  min: number;
  max: number;
}

export interface SurgeonPreferencesError {
  type:
    | 'SURGEON_PREFERENCES'
    | 'SURGEON_PREFERENCE_IMPLANTS'
    | 'SURGEON_PREFERENCE_IMPLANT_MEASUREMENTS';
  code: SurgeonPreferencesErrorCodeType;
  measurementType?: SurgeonPreferenceMeasurementType;
  procedure?: ImplantType;
  levelType?: LevelType;
  value?: string | number;
  minValue?: string | number;
  maxValue?: string | number;
  range?: [number, number];
}

export interface CaseReportError {
  type: 'CASE_REPORT' | 'CASE_STATEMENTS' | 'CASE_PATHOLOGIES';
  code: CaseReportErrorCodeType;
  procedure?: ImplantType;
  levelType?: LevelType;
  value?: string | number;
  minValue?: string | number;
  maxValue?: string | number;
  range?: [number, number];
}

export interface IVendorAssignment extends IMetadata {
  vendorAssignmentId: number;
  caseId: number;
  organizationId: number;
  organization: IOrganization;
  taskCategory: VendorTaskCategory;
}

export interface ICaseLevel extends IMetadata {
  caseLevelId: number;
  caseId: number;
  level: LevelType;
  partType?: PartType;
  isFused?: boolean;
  threadType?: ThreadType;
  threadedHoles?: number;
  anteriorInstrumentation?: boolean;
  otherVendor?: boolean;
  implantPartDetailId?: number;
  implantType?: ImplantType;
  implantDirection?: ImplantDirection;
  implantOrientation?: ImplantOrientation;
}

export interface IFreeAgentConfiguration {
  accessTokenUrl: string;
  graphQLUrl: string;
  clientId: string;
  clientSecret: string;
  disabled: boolean;
  taskDisabled: boolean;
}

export interface ISettings extends IMetadata {
  settingsId: number;
  application: IApplicationSettings;
  cloudDesign: ICloudDesignSettings;
  testerEmails: TesterEmailSettings;
  emails: EmailSettings;
  networkDays: Record<string | number, unknown>;
  form19: Form19Type;
  form20: IForm20Product[];
  onShape: IOnShapeSettings;
}

export interface IEmailConfiguration {
  noReplyAddress: string;
  clientUrl: string;
  disabled: boolean;
  notificationsDisabled: boolean;
  supportEmail: string;
  smsOriginationNumber: string;
  verificationCodeTimeout: number;
}

export interface IOnShapeConfiguration {
  apiUrl: string;
  accessKey: string;
  secretKey: string;
  cacheDisabled: boolean;
}

export interface IOneSignalConfiguration {
  apiKey: string;
  apiId: string;
  notificationDisabled: boolean;
}

export interface IGreenlightGuruConfiguration {
  exportApiUrl: string;
  apiKey: string;
  cacheDisabled: boolean;
}

export interface IDaisyConfiguration {
  awsAccessKeyId?: string;
  awsSecretAccessKey?: string;
  awsS3Bucket: string;
  awsDaisyListenerLambdaUrl: string;
  awsStepFunctionLambdaUrl: string;
  awsStepFunctionAccessKeyId: string;
  awsStepFunctionSecretAccessKey: string;
  awsStepFunctionRegion: string;
  awsStepFunctionS3Bucket: string;
  disabled: boolean;
}

export interface ICloudDesignConfiguration {
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  awsS3Bucket: string;
  awsSqsQueueUrl: string;
  disabled: boolean;
}

export interface IAwsS3AssetsBucketConfiguration {
  awsAccessKeyId: string;
  awsRegion: string;
  awsSecretAccessKey: string;
  awsS3Bucket: string;
}

export interface IAwsSimpleEmailServiceConfiguration {
  server: string;
  port: number;
  region: string;
  username: string;
  password: string;
}

export interface IAwsSimpleNotificationServiceConfiguration {
  region: string;
  username: string;
  password: string;
}

export interface IEasyPostConfiguration {
  apiKey: string;
  apiTestKey: string;
  webhookSecret: string;
  disabled: boolean;
}

export interface IConfigurationForm {
  freeAgent: IFreeAgentConfiguration;
  email: IEmailConfiguration;
  onShape: IOnShapeConfiguration;
  oneSignal: IOneSignalConfiguration;
  daisy: IDaisyConfiguration;
  cloudDesign: ICloudDesignConfiguration;
  awsSimpleEmailService: IAwsSimpleEmailServiceConfiguration;
  easyPost: IEasyPostConfiguration;
}

export interface IConfigurationValueStructure {
  key: string;
  value: {
    type: ConfigurationValueType;
    value: unknown;
  };
}

export interface IConfiguration {
  key: string;
  value: IConfigurationValueStructure[];
}

export interface IQueueMessageBody {
  key: string;
  id: number;
  environment: string;
}

export interface IDaisySegmentationQueueMessageBody extends IQueueMessageBody {
  caseNumber: string;
  status: DaisySegmentationStatus;
  stage: DaisySegmentationStage;
  segmentation: DaisySegmentationType;
  message: string;
}

export interface ICaseAnalysisImaging extends IMetadata {
  caseAnalysisImagingId: number;
  caseAnalysisId: number;
  asset: IAsset;
  pixelSpacing: number | null;
  imagingType: CaseAnalysisImagingType;
  landmarks: CaseImagingLandmark[];
}

export interface ICaseAnalysisMeasurement extends IMetadata {
  caseAnalysisMeasurementId: number;
  caseAnalysisId: number;
  measurementType: MeasurementType;
  levelType: LevelType | null;
  value: number;
  justification: MissedGoalJustification | null;
}

export interface ICaseAnalysis extends IMetadata {
  caseAnalysisId: number;
  analysisType: PostOpAnalysisType;
  caseId: number;
  asset?: IAsset;
  status: CaseAnalysisStatus;
  caseAnalysisImaging: ICaseAnalysisImaging[];
  caseAnalysisMeasurements: ICaseAnalysisMeasurement[];
  approvedBy: number | null;
  approvedByUser: IUser | null;
  approvedAt: Date | null;
  measurementsVersion: MeasurementsVersionType;
  isAutomatic: boolean;
}

export interface ICaseReport extends IMetadata {
  caseReportId: number;
  caseId: number;
  status: CaseReportStatusType;
  riskAssessment?: CaseRiskAssessmentType;
  correctionGoalSagittal?: SagittalGoalType;
  correctionGoalSagittalOther?: string;
  correctionGoalCoronal?: CoronalGoalType;
  correctionGoalCoronalOther?: CoronalGoalType;
  correctionGoalDiscHeightOnly?: boolean;
  correctionGoalNote?: string;
  segmentationAssetId?: number;
  segmentationSliceThickness?: SegmentationSliceThicknessType;
  segmentationPassed: boolean;
  segmentationImagingAssessment?: SegmentationImagingAssessmentType;
  segmentationImageDate?: Date;
  segmentedByQualifiedOperator?: boolean;
  segmentationPerformedByUser?: IUser;
  segmentationPerformedBy?: number;
  segmentationReviewedByUser?: IUser;
  segmentationReviewedBy?: number;
  segmentationType?: SegmentationType;
  reportReviewRequestedAt?: Date;
  reportReviewedByUser?: IUser;
  reportReviewedAt?: Date;
  reportReviewNote?: string;
  measurementLordosisDifference?: number;
  measurementLordosisDifferenceRationale?: LordosisDifferenceRationaleType[];
  measurementLordosisDifferenceRationaleNote?: string;
  measurementNote?: string;
  planFeedback?: PlanFeedbackType[];
  planFeedbackOther?: string;
  hasEditedVertebralBodies?: boolean;
  caseReportStatements?: ICaseReportStatement[];
  casePathologies?: ICasePathology[];
  caseReportRevisions?: ICaseReportRevision[];
}

export interface ICaseReportStatement extends IMetadata {
  caseReportStatementId: number;
  caseReportId: number;
  statementType: StatementType;
  statement: Statement;
  response: boolean;
  note?: string;
}

export interface ICasePathology extends IMetadata {
  casePathologyId: number;
  caseReportId: number;
  caseId: number;
  pathologyType: PathologyType;
  pathologySelected?: boolean;
  pathologyNote?: string;
}

export interface ICaseReportRevision extends IMetadata {
  caseReportRevisionId: number;
  caseReportId: number;
  revision: number;
  description: string;
}
