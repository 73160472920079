import * as Yup from 'yup';
import { AssetType, LordosisDifferenceRationaleType, Statement } from '@workflow-nx/common';

export const caseReportStatementSchema = Yup.object().shape({
  statement: Yup.string().required(),
  response: Yup.bool().required('Response is Required'),
  note: Yup.string().when(['statement', 'response'], {
    is: (statement: string, response: boolean) =>
      (response === true && statement === Statement.TlifCKyphotic) ||
      (response === false &&
        (statement === Statement.AnteriorDiscHeightsReasonable ||
          statement === Statement.PosteriorDiscHeightsReasonable ||
          statement === Statement.IntervertebralLordosisReasonable ||
          statement === Statement.PelvicIncidenceAndLumbarLordosisReasonable ||
          statement === Statement.AlifxScrewTrajectoriesAcceptable ||
          statement === Statement.CageFootprintFit)),
    then: (schema) => schema.required('Note is Required'),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const casePathologySchema = Yup.object().shape({
  pathologyType: Yup.string(),
  pathologyNote: Yup.string().nullable(),
});

export const patientRecordSchema = Yup.object().shape({
  pelvicIncidence: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('Pelvic Incidence must be a number')
    .test(
      'range',
      'Pelvic Incidence must be between 1° and 180°',
      (value) => value === null || (value !== undefined && value >= 1 && value <= 180),
    ),
  lumbarLordosis: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('Lumbar Lordosis must be a number'),
  lumbarCoronalCobb: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('Lumbar Coronal Angle must be a number'),
  sagittalVerticalAxis: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('Sagittal Vertical Axis must be a number'),
  l4S1LordoticDistribution: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('L4S1 Lordotic Distribution must be a number'),
  globalTilt: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('Global Tilt must be a number'),
  pelvicTilt: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('Pelvic Tilt must be a number'),
  sacralSlope: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('Sacral Slope must be a number'),
  c7SagittalVerticalLine: Yup.number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .typeError('c7 Sagittal Vertical Line must be a number'),
});

export const createCaseReportDialogSchema = (
  checkFileExists: (value: any, assetType: AssetType) => boolean,
  isAutoSegmented: boolean,
) => {
  return Yup.object().shape({
    segmentationImagingAssessment: Yup.string().required(
      'Segmentation Imaging Assessment Required',
    ),
    segmentationSliceThickness: Yup.string().required('Segmentation Slice Thickness Required'),
    segmentedByQualifiedOperator: Yup.bool().required('Segmented By Qualified Operator Required'),
    riskAssessment: Yup.string(),
    reportReviewedBy: Yup.string().when('riskAssessment', {
      is: 'CASE_REVIEW_QA_REVIEW',
      then: (schema) => schema.required('Case Report Reviewer Required'),
    }),
    segmentationPassed: Yup.bool(),
    segmentationPerformedBy: Yup.string().test(
      'required-segmentationPerformedBy',
      'Segmentation Performed By Required',
      function (value) {
        const { segmentedByQualifiedOperator } = this.parent;
        return !(segmentedByQualifiedOperator === false && isAutoSegmented === false && !value);
      },
    ),
    segmentationReviewedBy: Yup.string().test(
      'required-segmentationReviewedBy',
      'Segmentation Reviewed By Required',
      function (value) {
        const { segmentedByQualifiedOperator } = this.parent;
        return !(segmentedByQualifiedOperator === false && isAutoSegmented === false && !value);
      },
    ),
    caseReportStandingXrayLateralMeasured: Yup.mixed<File>()
      .test(
        'hasCaseReportStandingXrayLateralMeasured',
        'Standing Xray Lateral Measured Required',
        (value) => checkFileExists(value, AssetType.CaseReportStandingXrayLateralMeasured),
      )
      .nullable(),
    patientRecord: patientRecordSchema,
    measurementLordosisDifference: Yup.number(),
    measurementLordosisDifferenceRationale: Yup.array()
      .of(Yup.string())
      .when('measurementLordosisDifference', {
        is: (value: number) => value > 10,
        then: (schema) =>
          schema
            .min(1, 'At least one rationale difference reason must be selected')
            .required('Field is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
    measurementLordosisDifferenceRationaleNote: Yup.string().when(
      'measurementLordosisDifferenceRationale',
      {
        is: (rationaleArray: string[]) =>
          rationaleArray && rationaleArray.includes(LordosisDifferenceRationaleType.Other),
        then: (schema) => schema.required('Rationale note is required when "Other" is selected'),
        otherwise: (schema) => schema.notRequired(),
      },
    ),
    measurementNote: Yup.string(),
    correctionGoalNote: Yup.string(),
    correctionGoalSagittal: Yup.string().required('Sagittal Correction Required'),
    correctionGoalSagittalOther: Yup.string().when('correctionGoalSagittal', {
      is: 'OTHER',
      then: (schema) => schema.required('Sagittal Correction Other Required'),
    }),
    correctionGoalCoronal: Yup.string().required('Coronal Correction Required'),
    correctionGoalCoronalOther: Yup.string().when('correctionGoalCoronal', {
      is: 'OTHER',
      then: (schema) => schema.required('Coronal Correction Other Required'),
    }),
    planFeedback: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one Feedback must be selected')
      .required('Plan Feedback required'),
    planFeedbackOther: Yup.string().when('planFeedback', {
      is: (feedbackArray: string) => feedbackArray && feedbackArray.includes('OTHER'),
      then: (schema) => schema.required('Plan Feedback Other Required'),
    }),
    caseReportStatements: Yup.array().of(caseReportStatementSchema),
    casePathologies: Yup.array().of(casePathologySchema),
  });
};
