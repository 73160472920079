import React, { useReducer, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  FormLabel,
  Stack,
  Typography,
  Button as MuiButton,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Page from '../../../components/Page';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_USER, FIND_USER } from '../../../gql';
import SplashScreen from '../../../components/SplashScreen';
import { CustomAppBar } from '../../../components/CustomAppBar';
import { format, ISurgeonPreferences, IUser, Permission, UserRoleType } from '@workflow-nx/common';
import useAuth from '../../../hooks/useAuth';
import { UserDetailsView } from './UserDetailsView';
import { Button } from '@workflow-nx/ui';
import { EditUserPermissionsDialog } from './EditUserPermissionsDialog';
import { EditUserDetailsDialog } from './EditUserDetailsDialog';
import { EditAssociatedUsersDialog } from './EditAssociatedUsersDialog';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

export type UserViewActionType = {
  type: 'refetch' | 'INIT';
  data?: any;
};

type UserViewStateType = {
  canEditUser: boolean;
  canEditUserPermissions: boolean;
  user?: IUser;
  preferences?: ISurgeonPreferences;
};

export const casePermissions = [
  {
    key: Permission.CloneCase,
    value: 'Clone Case',
  },
  {
    key: Permission.EditCase,
    value: 'Edit Case',
  },
  {
    key: Permission.EditDiscussions,
    value: 'Edit Discussions',
  },
  {
    key: Permission.ListCases,
    value: 'List Cases',
  },
  {
    key: Permission.ListPostOpAnalyses,
    value: 'List Post-op Analyses',
  },
  {
    key: Permission.ListTasks,
    value: 'List Tasks',
  },
  {
    key: Permission.ManageCase,
    value: 'Manage Case',
  },
  {
    key: Permission.ManageCaseLocation,
    value: 'Manage Case Location',
  },
  {
    key: Permission.ManageCaseInventory,
    value: 'Manage Case Inventory',
  },
  {
    key: Permission.ManagePostOpAnalysis,
    value: 'Manage Post-op Analysis',
  },
  {
    key: Permission.ManageTask,
    value: 'Manage Task',
  },
  {
    key: Permission.QaReviewCase,
    value: 'QA Review Case',
  },
  {
    key: Permission.ViewCase,
    value: 'View Case',
  },
  {
    key: Permission.ViewPostOpAnalysis,
    value: 'View Post-op Analysis',
  },
  {
    key: Permission.ViewTask,
    value: 'View Task',
  },
];

export const userAndOrganizationPermissions = [
  {
    key: Permission.ListOrganizations,
    value: 'List Institutions',
  },
  {
    key: Permission.ListSurgeons,
    value: 'List Surgeons',
  },
  {
    key: Permission.ListUsers,
    value: 'List Users',
  },
  {
    key: Permission.ManageOrganization,
    value: 'Manage Organization',
  },
  {
    key: Permission.ManageSurgeon,
    value: 'Manage Surgeon',
  },
  {
    key: Permission.ManageSurgeonPreferences,
    value: 'Manage Surgeon Preferences',
  },
  {
    key: Permission.ManageSurgeonExcludeImplants,
    value: 'Manage Surgeon Exclude Implants',
  },
  {
    key: Permission.ManageUser,
    value: 'Manage User',
  },
  {
    key: Permission.ManageUserPermissions,
    value: 'Manage User Permissions',
  },
  {
    key: Permission.ViewOrganization,
    value: 'View Institution',
  },
  {
    key: Permission.ViewUser,
    value: 'View User',
  },
];

export const reportPermissions = [
  {
    key: Permission.ListReports,
    value: 'List Reports',
  },
  {
    key: Permission.ReportExportCaseData,
    value: 'Export Case Data Report',
  },
  {
    key: Permission.ForecastReport,
    value: 'Forecast Report',
  },
];

export const opsPermissions = [
  {
    key: Permission.ManagePriceLevel,
    value: 'Manage Price Level',
  },
  {
    key: Permission.ListPriceLevels,
    value: 'List Price Levels',
  },
  {
    key: Permission.ViewPriceLevel,
    value: 'View Price Level',
  },
];

export const adminPermissions = [
  {
    key: Permission.ManageImplantPartDetails,
    value: 'Manage Implant Part Details',
  },
  {
    key: Permission.ManageSiteSettings,
    value: 'Manage Site Settings',
  },
  {
    key: Permission.ManageSiteConfiguration,
    value: 'Manage Site Configuration',
  },
];

const permissionsCategories = [
  {
    key: casePermissions,
    value: 'Case Permissions',
  },
  {
    key: userAndOrganizationPermissions,
    value: 'User / Institution Permissions',
  },
  {
    key: reportPermissions,
    value: 'Report Permissions',
  },
  {
    key: opsPermissions,
    value: 'Operations Permissions',
  },
  {
    key: adminPermissions,
    value: 'Admin Permissions',
  },
];

const UserView = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [openEditUserPermissionsDialog, setOpenEditUserPermissionsDialog] = useState(false);
  const [deleteUser] = useMutation(DELETE_USER);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const { userId } = useParams<{
    userId?: string;
  }>();
  const { data, loading, refetch } = useQuery(FIND_USER, {
    variables: { userId: Number(userId) },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data?.user) {
        navigate('/app/users');

        return;
      }

      dispatch({
        type: 'INIT',
        data,
      });
    },
  });

  const handleDeleteUserClick = async () => {
    try {
      await confirm({
        title: `Delete User ${format.formatName(data?.user)}`,
        description: 'Are you sure you want to delete this user?',
      });

      try {
        await deleteUser({
          variables: {
            userId: data?.user?.userId,
          },
        });

        enqueueSnackbar(`User ${format.formatName(data?.user)} was successfully deleted.`, {
          variant: 'success',
        });

        navigate('/app/users');
      } catch (errors) {
        console.error(errors);
        enqueueSnackbar('Error deleting User', {
          variant: 'error',
        });
      }
    } catch (errors) {
      console.error(errors);
    }
  };

  const handleUpdate = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      dispatch({ type: 'refetch' });
    }
    setOpenEditUserDialog(false);
    setOpenEditUserPermissionsDialog(false);
  };

  const initialState: UserViewStateType = {
    canEditUser: false,
    canEditUserPermissions: false,
  };

  const reducer = (state: UserViewStateType, action: UserViewActionType): UserViewStateType => {
    let updatedState: UserViewStateType = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case 'refetch':
        refetch();
        break;
      case 'INIT': {
        const user = action.data.user;
        const isEditableUser = auth?.hasPermission?.([Permission.ManageUser]);
        const isEditableUserPermissions = auth?.hasPermission?.([Permission.ManageUserPermissions]);

        updatedState = {
          user,
          preferences: user?.preferences,
          canEditUser: !!isEditableUser,
          canEditUserPermissions: !!isEditableUserPermissions,
        };
      }
    }
    return updatedState;
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const userActions = auth?.hasPermission?.([Permission.ManageUser])
    ? [
        <MuiButton color={'error'} variant={'contained'} onClick={handleDeleteUserClick}>
          Delete
        </MuiButton>,
      ]
    : [];

  return loading || !data?.user ? (
    <SplashScreen />
  ) : (
    <Page title={'User'}>
      <Container>
        <CustomAppBar
          navigateBackUrl={'/app/users'}
          title={`${format.formatName(data.user)} - ${format.formatUserRole(data?.user?.role)}`}
          actions={userActions}
        />
        <Card>
          <CardContent>
            <Box m={1}>
              <UserDetailsView
                onEdit={() => setOpenEditUserDialog(true)}
                user={data?.user}
                canEdit={state.canEditUser}
              />
            </Box>
          </CardContent>
        </Card>
        <Box mt={2} />
        {data?.user.role === UserRoleType.SalesRep ? (
          <EditAssociatedUsersDialog
            user={data?.user}
            role={UserRoleType.Surgeon}
            onClose={handleUpdate}
          />
        ) : null}
        <Box mt={2} />

        <Card>
          <CardContent>
            <Box m={1}>
              <Stack flexDirection={'row'} justifyContent={'space-between'}>
                <Typography variant={'h4'}>
                  {format.formatUserRole(state?.user?.role)} User Permissions{' '}
                </Typography>
                {state.canEditUserPermissions ? (
                  <Button
                    onClick={() => setOpenEditUserPermissionsDialog(true)}
                    variant={'contained'}
                    label={'Edit Permissions'}
                  />
                ) : null}
              </Stack>
            </Box>
            {permissionsCategories.map((list, index) => {
              const permissionCategory = list.key.filter((permissions) =>
                state.user?.permissions.includes(permissions.key),
              );
              if (permissionCategory && permissionCategory.length) {
                return (
                  <Box mx={1} mb={3} key={index}>
                    <Box mt={2} mb={1}>
                      <FormLabel component="legend">{list.value}</FormLabel>
                    </Box>
                    <Stack
                      flexDirection={'row'}
                      rowGap={1}
                      justifyContent={'flex-start'}
                      flexWrap={'wrap'}
                    >
                      {permissionCategory.map((userPermissions, index) => (
                        <Stack
                          flexDirection={'row'}
                          gap={2}
                          justifyContent={'space-evenly'}
                          mx={1}
                          key={index}
                        >
                          <Typography variant={'body1'}>{userPermissions.value}</Typography>
                          {index < permissionCategory.length - 1 ? (
                            <Divider orientation="vertical" />
                          ) : null}
                        </Stack>
                      ))}
                    </Stack>
                  </Box>
                );
              } else {
                return null;
              }
            })}
          </CardContent>
        </Card>
      </Container>
      <EditUserDetailsDialog open={openEditUserDialog} user={data?.user} onClose={handleUpdate} />

      <EditUserPermissionsDialog
        open={openEditUserPermissionsDialog}
        user={data?.user}
        onClose={handleUpdate}
      />
    </Page>
  );
};

export default UserView;
