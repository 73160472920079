import { useMutation, useQuery } from '@apollo/client';
import { ApolloError, gql } from '@apollo/client/core';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import {
  DowntimeDateRange,
  EmailSettings,
  IApplicationSettings,
  IForm19JsonLevel,
  IForm20Product,
  IOnShapeSettings,
  ImplantDrawingSettings,
  Permission,
  ProductionStageEstimate,
  ProductionStageType,
  TesterEmailSettings,
  UserRoleType,
} from '@workflow-nx/common';
import { sortBy } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useReducer, useState } from 'react';
import ActionButton from '../../components/ActionButton';
import { CustomAppBar } from '../../components/CustomAppBar';
import Page from '../../components/Page';
import config from '../../extras/config';
import {
  DELETE_IMPLANT_DRAWINGS_CACHE,
  DELETE_IMPLANT_TEMPLATE_CACHE,
  SYNC_EXTERNAL_DATA,
} from '../../gql';
import useAuth from '../../hooks/useAuth';
import { ApplicationForm } from './Application/ApplicationForm';
import { CloudDesignForm } from './CloudDesign/CloudDesignForm';
import { ConfigureFormsView } from './ConfigureForms/ConfigureFormsView';
import { EmailSettingsForm } from './EmailSettingsForm';
import { ImplantDrawingDocumentsForm } from './ImplantDrawingDocumentsForm';
import { NetworkDaysForm } from './NetworkDaysForm';
import { OnShapeForm } from './OnShapeForm';
import { RegionAndTerritoriesForm } from './RegionAndTerritoriesForm';
import { ServicesForm } from './ServicesForms/ServicesForm';
import TagsForm from './TagsForm';
import { TesterEmailSettingsForm } from './TesterEmailSettingsForm';
import { ImplantPartDetailsView } from './ImplantPartDetailsForm/ImplantPartDetailsView';

export type SettingsViewActionType = {
  type: 'refetch' | 'INIT';
  data?: any;
};

type SettingsViewStateType = {
  networkDays: {
    holidays: DowntimeDateRange[];
    outages: DowntimeDateRange[];
    stageVendorMapping: Partial<Record<ProductionStageType, number[]>>;
    stages: ProductionStageEstimate[];
  };
  testerEmails: TesterEmailSettings;
  emails: EmailSettings;
  onShape: IOnShapeSettings;
  application: IApplicationSettings;
  form20: IForm20Product[];
  form19: IForm19JsonLevel[];
  implantDrawings: ImplantDrawingSettings;
};

export const SettingsView = () => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoadingModel, setShowLoadingModel] = useState(false);
  const [deleteImplantTemplateCache] = useMutation(DELETE_IMPLANT_TEMPLATE_CACHE);
  const [deleteImplantDrawingsCache] = useMutation(DELETE_IMPLANT_DRAWINGS_CACHE);

  const [testNotifications] = useMutation(gql`
    mutation ValidateNotifications {
      validateNotifications {
        status
      }
    }
  `);

  const [syncExternalData, { data, loading: syncExternalDataLoading }] =
    useMutation(SYNC_EXTERNAL_DATA);
  const { refetch } = useQuery(
    gql`
      query {
        settings {
          emails
          testerEmails
          networkDays
          form19
          form20
          onShape
          implantDrawings
          application
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        dispatch({ type: 'INIT', data });
      },
    },
  );
  const { hasRole, hasPermission } = useAuth();

  const reducer = (
    state: SettingsViewStateType,
    action: SettingsViewActionType,
  ): SettingsViewStateType => {
    let updatedState: SettingsViewStateType = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case 'INIT': {
        updatedState = {
          testerEmails: action?.data?.settings?.testerEmails ?? updatedState.testerEmails,
          emails: action?.data?.settings?.emails ?? updatedState.emails,
          networkDays: action?.data?.settings?.networkDays ?? updatedState.networkDays,
          onShape: action?.data?.settings?.onShape ?? updatedState.onShape,
          application: action?.data?.settings?.application ?? updatedState.application,
          form20:
            sortBy(action?.data?.settings?.form20, ['sku']) ?? sortBy(updatedState.form20, ['sku']),
          form19: action?.data?.settings?.form19 ?? updatedState.form19,
          implantDrawings: action?.data?.settings?.implantDrawings ?? updatedState.implantDrawings,
        };
      }
    }
    return updatedState;
  };

  const [state, dispatch] = useReducer(reducer, {
    networkDays: {
      holidays: [],
      outages: [],
      stages: [],
      stageVendorMapping: {},
    },
    emails: {
      draftCaseCreated: [],
      caseCreated: [],
      caseUpdated: [],
      taskUpdated: [],
      caseProposed: [],
      peerReview: [],
      qaReview: [],
      qaApproved: [],
      caseFinalRelease: [],
      caseCompleted: [],
      postOpAnalysisImagingUpdated: [],
      caseCancelled: [],
      surgeonCreated: [],
      surgeryDateChanged: [],
    },
    testerEmails: {
      whitelist: [],
    },
    onShape: {
      urls: {
        production: {
          ACDF: '',
          ACDF_X: '',
          ACDF_X_NO_CAM: '',
          ALIF: '',
          ALIF_X: '',
          LLIF_LEFT: '',
          LLIF_RIGHT: '',
          TLIF_C_LEFT: '',
          TLIF_C_RIGHT: '',
          TLIF_CA_LEFT: '',
          TLIF_CA_RIGHT: '',
          TLIF_O_LEFT: '',
          TLIF_O_RIGHT: '',
        },
      },
      m4: {
        urls: {
          PELVIS: '',
          ACDF: '',
          ACDF_X: '',
          ACDF_X_NO_CAM: '',
          ALIF: '',
          ALIF_X: '',
          LLIF: '',
          TLIF_C: '',
          TLIF_CA: '',
          TLIF_O: '',
        },
      },
      v3: {
        urls: {
          PELVIS: '',
          ACDF: '',
          ACDF_X: '',
          ACDF_X_NO_CAM: '',
          ALIF: '',
          ALIF_X: '',
          LLIF: '',
          TLIF_C: '',
          TLIF_CA: '',
          TLIF_O: '',
        },
      },
    },
    application: {
      product: {
        kitCartonStartDate: undefined,
        m4lStartDate: undefined,
        tlifCM4lStartDate: undefined,
        tlifCOrientationStartDate: undefined,
        tlifCArticulatingStartDate: undefined,
        alifXRotationLockStartDate: undefined,
        reusableInstrumentsStartDate: undefined,
      },
      externalApps: {
        cyborgUrl: '',
        ventiUrl: '',
        designerReportUrl: '',
        autoXrUrl: '',
        autoPlanningUrl: '',
      },
      caseReports: {
        peerReviewUserIds: [],
      },
      featureFlags: {
        angledInstrumentsEnabled: false,
        autoCorrectEnabled: false,
        cervicalSupportEnabled: false,
        cyborgV2Enabled: false,
        daisyV2Enabled: false,
        fastImplantCuttingEnabled: false,
        implantManagementEnabled: false,
        implantSizeExclusionEnabled: false,
        nTopLumbarEnhancementsEnabled: false,
        reusableInstrumentsEnabled: false,
        caseReportAutomationEnabled: false,
      },
    },
    form20: [],
    form19: [],
    implantDrawings: {
      alif: { documentId: '', versions: [] },
      alifx: { documentId: '', versions: [] },
      tlifo: { documentId: '', versions: [] },
      tlifc: { documentId: '', versions: [] },
      llif: { documentId: '', versions: [] },
    },
  });

  const configForms = [
    {
      name: 'Maintenance',
      permissions: [],
      component: (
        <>
          <Typography variant={'h4'}>Maintenance</Typography>
          <Box mt={1} display={'flex'} gap={2}>
            <ActionButton onClick={handleClearDrawingsCacheClick} variant={'outlined'}>
              Clear Implant Drawings Cache
            </ActionButton>
            <ActionButton onClick={handleClearTemplateCacheClick} variant={'outlined'}>
              Clear Implant Template Cache
            </ActionButton>
            {hasRole && hasRole([UserRoleType.SiteAdministrator]) ? (
              <ActionButton onClick={handleSyncExternalDataClick} variant={'outlined'}>
                Sync External Data Now
              </ActionButton>
            ) : null}
            {hasRole && hasRole([UserRoleType.SiteAdministrator]) ? (
              <ActionButton onClick={handleTestNotificationsClick} variant={'outlined'}>
                Test Notifications
              </ActionButton>
            ) : null}
          </Box>
        </>
      ),
    },
    {
      name: 'Application',
      permissions: [],
      component: (
        <ApplicationForm
          application={state.application}
          onClose={(shouldUpdate) => {
            if (shouldUpdate) {
              refetch();
            }
          }}
        />
      ),
    },
    {
      name: 'Cloud Design',
      permissions: [],
      component: <CloudDesignForm />,
    },
    {
      name: 'Tester Email Settings',
      permissions: [],
      component: (
        <TesterEmailSettingsForm
          emails={state.testerEmails}
          onClose={(shouldUpdate) => {
            if (shouldUpdate) {
              refetch();
            }
          }}
        />
      ),
      isDevEnvOnly: true,
    },
    {
      name: 'Notifications',
      permissions: [],
      component: (
        <EmailSettingsForm
          emails={state.emails}
          onClose={(shouldUpdate) => {
            if (shouldUpdate) {
              refetch();
            }
          }}
        />
      ),
    },
    {
      name: 'Production Calendar',
      permissions: [],
      component: <NetworkDaysForm networkDays={state.networkDays} onSave={refetch} />,
    },
    {
      name: 'Multiple Forms',
      permissions: [],
      component: (
        <ConfigureFormsView
          form20={state.form20}
          form19={state.form19}
          onClose={(shouldUpdate) => {
            if (shouldUpdate) {
              refetch();
            }
          }}
        />
      ),
    },
    {
      name: 'Tags',
      permissions: [],
      component: <TagsForm />,
    },
    {
      name: 'Implant Drawings',
      permissions: [],
      component: (
        <ImplantDrawingDocumentsForm
          documents={state.implantDrawings}
          onClose={(shouldUpdate) => {
            if (shouldUpdate) {
              refetch();
            }
          }}
        />
      ),
    },
    {
      name: 'Implant Management',
      permissions: [Permission.ManageImplantPartDetails],
      disabled: !state.application.featureFlags.implantManagementEnabled,
      component: (
        <ImplantPartDetailsView
          onShape={state.onShape}
          onClose={(shouldUpdate) => {
            if (shouldUpdate) {
              refetch();
            }
          }}
        />
      ),
    },
    {
      name: 'Regions and Territories',
      permissions: [],
      component: <RegionAndTerritoriesForm />,
    },
    {
      name: 'OnShape',
      permissions: [],
      component: (
        <OnShapeForm
          onShape={state.onShape}
          onClose={(shouldUpdate) => {
            if (shouldUpdate) {
              refetch();
            }
          }}
        />
      ),
    },
    {
      name: 'Services',
      permissions: [Permission.ManageSiteConfiguration],
      component: <ServicesForm />,
    },
  ];

  async function handleClearDrawingsCacheClick() {
    try {
      await confirm({
        title: `Clear the implant drawings cache?`,
        description: <>This will remove all the cached Greenlight Guru implant drawings.</>,
      });

      await deleteImplantDrawingsCache();

      enqueueSnackbar(`Implant drawings cache was successfully cleared.`, {
        variant: 'success',
      });
    } catch (errors) {
      console.error(errors);
      enqueueSnackbar('Error clearing implant drawings cache', {
        variant: 'error',
      });
    }
  }

  async function handleClearTemplateCacheClick() {
    try {
      await confirm({
        title: `Clear the implant template cache?`,
        description: <>This will remove all the cached OnShape parts in the template cache.</>,
      });

      await deleteImplantTemplateCache();

      enqueueSnackbar(`Implant template cache was successfully cleared.`, {
        variant: 'success',
      });
    } catch (errors) {
      console.error(errors);
      enqueueSnackbar('Error clearing implant template cache', {
        variant: 'error',
      });
    }
  }

  async function handleSyncExternalDataClick() {
    try {
      await confirm({
        title: `Sync External Data`,
        description: (
          <>
            Any new FreeAgent health system and hospital data will be synced with the aprevo®
            Digital Production System.
          </>
        ),
      });

      setShowLoadingModel(true);
      syncExternalData()
        .then(() => {
          console.log('Sync External Data complete');
        })
        .catch((err) => {
          console.error(err);
          if (err instanceof ApolloError) {
            if (err?.graphQLErrors?.[0]?.extensions?.code === 'UPDATE_SYNC_FREEAGENT_DISABLED') {
              enqueueSnackbar(err.message, {
                variant: 'error',
              });
              return;
            }
          } else {
            enqueueSnackbar('Error trying to sync external data', {
              variant: 'error',
            });
          }
        });
    } catch {
      enqueueSnackbar('Error trying to sync external data', {
        variant: 'error',
      });
    }
  }

  async function handleTestNotificationsClick() {
    try {
      await confirm({
        title: `Test Notifications`,
        description: (
          <>
            This will send a test notification to the current users email and any connected device
            they have.
          </>
        ),
      });

      await testNotifications();
    } catch {
      enqueueSnackbar('Error testing notifications', {
        variant: 'error',
      });
    }
  }

  return (
    <Page title={'Settings'}>
      <Container>
        <CustomAppBar title={'Settings'} />
        <Card>
          <CardContent>
            <Typography variant={'h4'}>Unique Device Identifiers</Typography>
            <Stack spacing={1} sx={{ width: '50%' }}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'body1'}>aprevo® Digital Planning</Typography>
                <Typography variant={'body1'}>00810056991890</Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'body1'}>aprevo® Digital Segmentation</Typography>
                <Typography variant={'body1'}>00810056991357</Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'body1'}>aprevo® Digital Workflow</Typography>
                <Typography variant={'body1'}>00810056991319</Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        <Box my={2} />
        <Stack spacing={2}>
          {configForms
            .filter((ele) => {
              return (
                (hasPermission?.(ele.permissions) || hasRole?.([UserRoleType.SiteAdministrator])) &&
                !ele.disabled
              );
            })
            .map((form) => {
              if (form?.isDevEnvOnly && config.environmentName === 'production') {
                return null;
              }
              return (
                <Card key={form.name}>
                  <CardContent>{form.component}</CardContent>
                </Card>
              );
            })}
        </Stack>
      </Container>
      <Modal open={showLoadingModel}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '1px solid #ccc',
            boxShadow: 24,
            p: 2,
          }}
        >
          <Typography variant={'h4'}>
            {syncExternalDataLoading
              ? 'Sync External Data In Progress'
              : 'Sync External Data Complete'}
          </Typography>
          <Stack alignItems={'center'} spacing={2} sx={{ mt: 2 }}>
            {syncExternalDataLoading ? (
              <>
                <Typography variant={'body1'}>Syncing external data...</Typography>
                <CircularProgress />
              </>
            ) : (
              <>
                <Typography variant={'body1'}>
                  Sync complete, {data?.updateSync?.count} item
                  {data?.updateSync?.count !== 1 ? 's' : ''} updated
                </Typography>
                <Button onClick={() => setShowLoadingModel(false)}>Close</Button>
              </>
            )}
          </Stack>
        </Box>
      </Modal>
    </Page>
  );
};
