import { faPenToSquare, faRightLong } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, IconButton, Link, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ActivityOriginType,
  ActivityType,
  CarrierType,
  ICaseResult,
  IPlan,
  LevelResult,
  Permission,
  PlanRejectionReason,
  format,
} from '@workflow-nx/common';
import { TimelineActivity } from './DiscussionTabView';
import _ from 'lodash';
import { ProposedPlanRejectionDialog } from '../ProposedPlanRejectionDialog';
import { useState } from 'react';
import useAuth from '../../../../hooks/useAuth';

const useStyles = makeStyles(() => ({
  caseUpdateArrow: {
    padding: '0 3px',
  },
}));

export function TimelineInfoContent(props: {
  activity: TimelineActivity;
  disabled?: boolean;
  onUpdate?: () => void;
}) {
  const classes = useStyles();
  const auth = useAuth();
  const [openProposedPlanRejectionDialog, setOpenProposedPlanRejectionDialog] = useState(false);

  const activityType = props.activity?.activityType;
  const originType = props.activity?.originType;

  const setTimelineContent = () => {
    let contentTitle: string;
    let contentSubTitle: JSX.Element | null = null;
    let contentMain: JSX.Element | null = null;

    if (activityType === ActivityType.CaseStageProductionComplete && props.activity.entry) {
      contentTitle = 'Case Complete';
      contentSubTitle = (
        <span>
          {`${
            props.activity.entry.filter((result: ICaseResult) => result.result === LevelResult.Used)
              .length
          } used out of: ${props.activity.entry.length}`}
        </span>
      );
    } else if (originType === ActivityOriginType.Event) {
      contentTitle = format.formatEventType(props.activity?.entry?.eventType);
      contentSubTitle = <>{props.activity?.entry?.description}</>;
      contentMain = (
        <>
          <Typography variant={'body1'} color={'textPrimary'}>
            {format.formatSanitizeAndParseHtml(props.activity?.entry?.note)}
          </Typography>
          {props.activity?.entry?.carrierType ? (
            <Typography variant={'body1'} color={'textPrimary'}>
              <span>{`${format.formatCarrierType(props.activity.entry.carrierType)}: `}</span>
              <span>
                {props.activity.entry.carrierType !== CarrierType.Other ? (
                  <Link
                    target="_blank"
                    rel="noopener"
                    href={format.formatCarrierTrackingUrl(
                      props.activity.entry.carrierType,
                      props.activity.entry.trackingNumber ?? '',
                    )}
                  >
                    {props.activity.entry.trackingNumber}
                  </Link>
                ) : null}
              </span>
            </Typography>
          ) : null}
        </>
      );
    } else {
      contentTitle = format.formatActivityType(activityType);

      if (
        activityType.match(/^CASE_STAGE_.*_REOPENED$/) ||
        activityType === ActivityType.CaseUncancelled
      ) {
        contentMain = (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant={'body1'} color={'textPrimary'}>
              {props.activity.delta?.reason?.[0] ?? null}
            </Typography>
          </Box>
        );
      } else if (activityType === ActivityType.PlanProposalRejected) {
        //Fallback for the unlikely scenario where we receive an old activity that was not converted
        if (props.activity?.delta) {
          contentMain = (
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant={'body1'} color={'textPrimary'}>
                {props.activity.delta?.reason?.[0] ?? null}
              </Typography>
            </Box>
          );
        }

        //New Method
        else {
          const noteFieldValue = props.activity?.entry?.note;
          const sortedRejectionReasons = _.sortBy(
            props.activity.entry?.rejectionReasons,
            [],
          ) as PlanRejectionReason[];

          contentSubTitle = (
            <Stack
              direction={{ sm: 'column', md: 'row' }}
              spacing={{ sm: 0.25, md: 1 }}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              ml={-0.5}
              flexWrap={'wrap'}
              useFlexGap
            >
              {sortedRejectionReasons.map((reason, index) => (
                <Chip
                  label={format.formatPlanRejectionReason(reason)}
                  key={index}
                  size={'small'}
                  variant={'filled'}
                />
              ))}
            </Stack>
          );

          contentMain = (
            <Box display={'flex'} alignItems={'center'} gap={1} py={1}>
              <Typography variant={'body1'} color={'textPrimary'}>
                {noteFieldValue ?? null}
              </Typography>
            </Box>
          );
        }
      } else if (activityType === ActivityType.CaseCancelled) {
        contentSubTitle = (
          <>{format.formatCaseCancellationReasonType(props.activity?.delta?.[1])}</>
        );
        contentMain = (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant={'body1'}>{props.activity.delta?.[1]?.note ?? null}</Typography>
          </Box>
        );
      } else if (activityType === ActivityType.CaseLocationUpdated) {
        contentSubTitle = <>{props.activity?.delta?.location?.description?.[1]}</>;
        contentMain = (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant={'body1'}>{props.activity.delta?.reason[0] ?? null}</Typography>
          </Box>
        );
      } else if (activityType === ActivityType.CaseUpdateNumber) {
        contentSubTitle = (
          <>
            {`${props.activity.delta.number?.[0]}  `}
            <span>
              <FontAwesomeIcon
                icon={faRightLong}
                className={classes.caseUpdateArrow}
                fixedWidth={true}
                size={'xl'}
              />
            </span>
            {` ${props.activity.delta.number?.[1]}`}
          </>
        );
      } else if (activityType === ActivityType.CaseUpdateType) {
        contentSubTitle = <>{props.activity?.delta?.caseType?.[1]}</>;
        contentMain = (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant={'body1'}>{props.activity.delta?.reason[0] ?? null}</Typography>
          </Box>
        );
      } else if (activityType === ActivityType.CaseHold) {
        contentSubTitle = (
          <>{`${format.formatCaseHoldReasonType(props.activity?.delta?.[1]?.holdType)} Hold`}</>
        );
        contentMain = (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant={'body1'}>{props.activity.delta?.[1]?.reason ?? null}</Typography>
          </Box>
        );
      } else if (activityType === ActivityType.CaseRemoveHold) {
        //Kevin S 8/29/23 - Because I altered how the delta data is created for ActivityType.CaseRemoveHold, the following code is to account for different
        //delta data situations
        const surgeryDate =
          props.activity.delta?.surgeryDate?.[0] ?? props.activity.delta?.surgeryDate?.[1] ?? null;
        let isSurgeryDateTentative;
        if (props.activity.delta?.isSurgeryDateTentative?.length === 2) {
          isSurgeryDateTentative = props.activity.delta?.isSurgeryDateTentative[1];
        } else if (props.activity.delta?.isSurgeryDateTentative?.length === 1) {
          isSurgeryDateTentative = props.activity.delta?.isSurgeryDateTentative[0];
        } else {
          isSurgeryDateTentative = false;
        }

        contentSubTitle = (
          <>
            {`Surgery Date: `}
            {` ${surgeryDate ? format.formatISODate(surgeryDate) : 'Unknown'}`}
            {` - Tentative?: `}
            {isSurgeryDateTentative ? 'Yes' : 'No'}
          </>
        );

        contentMain = (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant={'body1'}>{props.activity.delta?.comment?.[0] ?? null}</Typography>
          </Box>
        );
      } else if (
        activityType === ActivityType.CaseStudyDateUpdated ||
        activityType === ActivityType.CaseUpdateStudyDate
      ) {
        //There's two possible expected delta return data with CaseUpdateStudyDate
        //1: Fresh study date set: delta = [null, {reason: string, studyDate: {}}]
        //2: Updated study date: delta =
        //{...reason: string[], studyDate: ['previousSetDate'], {...studyDate: string, expiryDate: string}}

        if (props.activity.delta?.[1]) {
          contentSubTitle = (
            <>
              {`New Study Date: ${props.activity.delta?.[1].studyDate?.studyDate}  -  New Expiry Date: ${props.activity.delta?.[1].studyDate?.expiryDate}`}
            </>
          );
          contentMain = (
            <>
              <Box display={'flex'} alignItems={'center'}>
                <Typography variant={'body1'} color={'textPrimary'}>
                  {props.activity.delta?.[1].reason ?? null}
                </Typography>
              </Box>
            </>
          );
        } else {
          contentSubTitle = (
            <>
              {`Study Date: ${props.activity.delta?.studyDate?.[0]} `}
              <span>
                <FontAwesomeIcon
                  icon={faRightLong}
                  className={classes.caseUpdateArrow}
                  fixedWidth={true}
                  size={'xl'}
                />
              </span>
              {` ${props.activity.delta?.studyDate?.[1].studyDate} -- `}
              {`Expiry Date: ${props.activity.delta?.expiryDate?.[0]} `}
              <span>
                <FontAwesomeIcon
                  icon={faRightLong}
                  className={classes.caseUpdateArrow}
                  fixedWidth={true}
                  size={'xl'}
                />
              </span>
              {` ${props.activity.delta?.studyDate?.[1].expiryDate}`}
            </>
          );
          contentMain = <>{props.activity.delta?.reason?.[0] ?? null}</>;
        }
      }
    }
    return { contentTitle, contentSubTitle, contentMain };
  };
  const { contentTitle, contentSubTitle, contentMain } = setTimelineContent();

  return (
    <Box>
      <>
        <Typography variant={'button'} color={'textSecondary'}>
          {activityType === ActivityType.PlanProposalRejected &&
          auth?.hasPermission?.([Permission.EditCase]) ? (
            <>
              <Stack justifyContent={'space-between'} direction={'row'}>
                <strong>{contentTitle}</strong>
                <IconButton
                  onClick={() => setOpenProposedPlanRejectionDialog(true)}
                  disabled={props.disabled}
                  size="small"
                >
                  <FontAwesomeIcon icon={faPenToSquare} fixedWidth={true} />
                </IconButton>
              </Stack>
              {contentSubTitle}
            </>
          ) : (
            <>
              <span>
                <strong>{contentTitle}</strong>
              </span>
              {contentSubTitle ? (
                <span>
                  {` - `}
                  {contentSubTitle}
                </span>
              ) : null}
            </>
          )}
        </Typography>
        <Box sx={{ whiteSpace: 'pre-wrap' }}>{contentMain}</Box>
      </>
      <ProposedPlanRejectionDialog
        open={openProposedPlanRejectionDialog}
        plan={{ planId: props.activity?.entry?.planId } as unknown as IPlan}
        activity={props.activity}
        onClose={() => {
          setOpenProposedPlanRejectionDialog(false);
          if (props?.onUpdate) {
            props?.onUpdate();
          }
        }}
      />
    </Box>
  );
}
