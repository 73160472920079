import {
  AssetType,
  caseUtils,
  IAsset,
  ICase,
  ILevels,
  ImplantOrientation,
  ImplantType,
  IPlan,
  ISurgeonPreferences,
  IUser,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import { sortBy } from 'lodash';
import {
  CaseReportCaseData,
  InputAssetType,
  LevelAssets,
} from '../../views/cases/CaseView/CasePlanningTab/CaseReportDialog/CaseReportDialog.reducer';
import { IMPLANT_TYPE_CUSTOM_SORT, MEASUREMENT_TYPE_CUSTOM_SORT } from '../surgeonPreferences';

export interface ILevelsHeaderData {
  levelType: LevelType;
  partType: PartType;
  levelCode: string;
  implantLevelType: ImplantType;
  direction: string;
  implantNameDimFile: string;
  implantNamePart: string;
  orientation?: ImplantOrientation | null;
}

export function getAssetTypesByImplantAndLevelType(
  implantType: ImplantType,
  levelType: LevelType,
): AssetType[] {
  let assetsByImplantAndLevelType: AssetType[] = [];

  switch (implantType) {
    case ImplantType.ALIF:
    case ImplantType.LLIF:
    case ImplantType.TLIFO:
      assetsByImplantAndLevelType = [
        `CASE_REPORT_${levelType}_IMPLANT_APP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_TOP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_BOTTOM_IMAGE`,
      ] as AssetType[];
      break;

    case ImplantType.TLIFC:
      assetsByImplantAndLevelType = [
        `CASE_REPORT_${levelType}_IMPLANT_APP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_POSITION_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_TOP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_BOTTOM_IMAGE`,
      ] as AssetType[];
      break;

    case ImplantType.ALIFX:
      assetsByImplantAndLevelType = [
        `CASE_REPORT_${levelType}_IMPLANT_APP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_TOP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_BOTTOM_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_SCREW_VERIFICATION_IMAGE`,
        // `CASE_REPORT_${levelType}_IMPLANT_SCREW_ANTERIOR_POSITION_IMAGE`,
        // `CASE_REPORT_${levelType}_IMPLANT_SCREW_LATERAL_POSITION_IMAGE`,
      ] as AssetType[];
      break;
    default:
      break;
  }

  return assetsByImplantAndLevelType;
}

export const getCaseLevelCaseReportAssets = (
  activeCase: ICase,
  caseAssets: IAsset[],
): LevelAssets[] => {
  const levelAssets: LevelAssets[] = [];

  const validCaseLevels = caseUtils.getValidCaseLevelsWithPartTypes(activeCase.levels);

  validCaseLevels.forEach((caseLevel) => {
    const inputAssetType: InputAssetType[] = [];
    const assetTypesByImplantAndLevelType = getAssetTypesByImplantAndLevelType(
      caseLevel.implantLevelType as ImplantType,
      caseLevel.levelType,
    );

    assetTypesByImplantAndLevelType.forEach((assetTypeByImplant) => {
      const asset = caseAssets.find((asset: IAsset) => asset.assetType === assetTypeByImplant);

      inputAssetType.push({
        level: caseLevel.levelType,
        inputAsset: asset ? undefined : null,
        deletedAssetId: 0,
        assetType: assetTypeByImplant,
        asset: asset ?? undefined,
      });
    });

    levelAssets.push({
      levelType: caseLevel.levelType,
      implantType: caseLevel.implantLevelType as ImplantType,
      inputAssets: inputAssetType,
    });
  });

  return levelAssets;
};

export function getAllCaseReportAssetTypes(caseLevelsInfo: ILevelsHeaderData[]): AssetType[] {
  const allCaseReportAssetTypes: AssetType[] = [
    AssetType.CaseReportVertebraePreEditImage,
    AssetType.CaseReportVertebraePostEditImage,
    AssetType.CaseReportImplantPreEditImage,
    AssetType.CaseReportImplantPostEditImage,
    AssetType.CaseReportStandingXrayLateralMeasured,
    AssetType.CaseReportCorrectionNoteImage,
    AssetType.PreopLateralImage,
    AssetType.PreopCoronalImage,
    AssetType.PlanLateralImage,
    AssetType.PlanCoronalImage,
  ];

  const foundAlifX = caseLevelsInfo?.some((level) => level.implantLevelType === ImplantType.ALIFX);
  if (foundAlifX) {
    allCaseReportAssetTypes.push(
      AssetType.CaseReportAlifXCagesAnteriorPosteriorConstructImage,
      AssetType.CaseReportAlifXCagesLateralConstructImage,
    );
  }

  for (const caseLevel of caseLevelsInfo) {
    const assetTypes = getAssetTypesByImplantAndLevelType(
      caseLevel.implantLevelType as ImplantType,
      caseLevel.levelType,
    );
    allCaseReportAssetTypes.push(...assetTypes);
  }

  return allCaseReportAssetTypes;
}

export const getCaseLevelSurgeonPreferences = (
  caseLevels: ILevels,
  preferences: ISurgeonPreferences,
): ISurgeonPreferences => {
  const caseLevelsPreferences: ISurgeonPreferences = { ...preferences };
  const foundImplantTypes = caseUtils.getValidImplantTypes(caseLevels);

  const sortedPreferredProcedures = sortBy(preferences?.preferredProcedures, (item) =>
    IMPLANT_TYPE_CUSTOM_SORT.indexOf(item),
  );

  const sortedSurgeonPreferenceImplants = sortBy(
    preferences?.surgeonPreferenceImplants.filter((item) =>
      foundImplantTypes.includes(item.implantType),
    ),
    (item) => IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType),
  );

  const sortedSurgeonPreferenceImplantMeasurements = sortBy(
    preferences?.surgeonPreferenceImplantMeasurements.filter((item) =>
      foundImplantTypes.includes(item.implantType),
    ),
    (item) => [
      IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType),
      MEASUREMENT_TYPE_CUSTOM_SORT.indexOf(item.measurementType),
    ],
  );

  caseLevelsPreferences.preferredProcedures = sortedPreferredProcedures;
  caseLevelsPreferences.surgeonPreferenceImplants = sortedSurgeonPreferenceImplants;
  caseLevelsPreferences.surgeonPreferenceImplantMeasurements =
    sortedSurgeonPreferenceImplantMeasurements;

  return caseLevelsPreferences;
};

export function getCaseReportCaseData(activeCase: ICase, casePlan?: IPlan): CaseReportCaseData {
  return {
    caseId: activeCase.caseId,
    caseNumber: activeCase.number,
    caseShortNumber: activeCase?.shortNumber,
    assignedUser: activeCase.assignedUser,
    fieldRepUser: activeCase.fieldRepUser,
    surgeonUser: activeCase.surgeonUser,
    caseLevels: activeCase.caseLevels,
    patientBirthDate: activeCase.patient.birthDate,
    patientGender: activeCase.patient.gender,
    mrn: activeCase?.patient?.mrn ? activeCase?.patient?.mrn : undefined,
    planId: casePlan?.planId ? casePlan?.planId : 0,
    planName: casePlan?.name ? casePlan?.name : '',
    planDescription: casePlan?.description ? casePlan?.description : '',
    plusLevelSize: casePlan?.plusLevelSize ? casePlan?.plusLevelSize : 0,
    caseSpineProfile: activeCase.spineProfile,
    caseSpineType: activeCase.spineType,
  };
}

export function isValidDesignEngineerUser(userId?: number, currentUser?: IUser) {
  if (!currentUser) {
    return false;
  }

  if (userId === currentUser?.userId) {
    return true;
  }

  return false;
}
