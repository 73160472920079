import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  caseUtils,
  format,
  ICase,
  ImplantType,
  IPatientRecord,
  IPlan,
  IPlanImplant,
  LevelType,
  PartType,
  PlanVersionType,
} from '@workflow-nx/common';
import React, { useState } from 'react';
import { EditPlanV1ImplantsDialog } from './EditPlanV1ImplantsDialog';
import ActionButton from '../../../../components/ActionButton';
import ImplantEditorDialog from './ImplantEditorDialog/ImplantEditorDialog.view';
import { ImplantEditorDialogProvider } from './ImplantEditorDialog/ImplantEditorDialog.context';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FIND_IMPLANT_PART_DETAIL, FIND_PLAN_IMPLANTS } from '../../../../gql';
import useAuth from '../../../../hooks/useAuth';
import { FeatureFlag } from '../../../../utils/featureFlags';
import { EditPlanNtopImplantsDialog } from './EditPlanNtopImplantsDialog';
import { NtopMetadataPopover } from './NtopMetadataPopover';
import { gql } from '@apollo/client/core';

export type PlanImplantLevels = {
  [key in LevelType]: IPlanImplant;
};

export function PlanImplantsTableView({
  patientRecord,
  plan,
  activeCase,
  disabled,
  editingAllowed,
  onUpdated,
}: {
  patientRecord: IPatientRecord;
  plan: IPlan;
  activeCase: ICase;
  disabled: boolean;
  editingAllowed: boolean;
  onUpdated: () => void;
}) {
  const { hasFeatureFlag } = useAuth();

  // const [findImplantPartDetail, { loading: loadingImplantPartDetail }] =
  //   useLazyQuery(FIND_IMPLANT_PART_DETAIL);

  const { refetch, loading: loadingPlanImplants } = useQuery(FIND_PLAN_IMPLANTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      planId: plan.planId,
    },
    onCompleted: (data) => {
      let hasImplantsWithScrews = false;
      const planImplantLevels: Partial<PlanImplantLevels> = {};
      activeCase.caseLevels.forEach((caseLevel) => {
        if (caseLevel.partType !== null) {
          const foundPlanImplant = data.planImplants.find(
            (planImplant: IPlanImplant) => planImplant.level === caseLevel.level,
          );
          if (
            [ImplantType.ALIFX, ImplantType.ACDFX, ImplantType.ACDFX_NO_CAM].includes(
              caseLevel.implantType ?? ImplantType.None,
            )
          ) {
            hasImplantsWithScrews = true;
          }
          planImplantLevels[caseLevel.level] =
            foundPlanImplant ??
            ({
              planId: plan.planId,
              bullet: {},
              level: caseLevel.level,
              partType: caseLevel.partType,
              threadHeight: 0,
              excludedImplantSizes: [],
            } as unknown as IPlanImplant);
        }
      });

      setHasImplantsWithScrews(hasImplantsWithScrews);
      setPlanImplantLevels(planImplantLevels);
    },
  });

  const [openEditPlanImplantsDialog, setOpenEditPlanImplantsDialog] = useState(false);
  const [hasImplantsWithScrews, setHasImplantsWithScrews] = useState(false);
  const [showImplantEditorDialog, setShowImplantEditorDialog] = useState(false);
  const [planImplantLevels, setPlanImplantLevels] = useState<Partial<PlanImplantLevels>>({});

  const { hasPelvisHighPelvicIncidence, hasPelvisOblique, hasPelvisHighCrest } =
    activeCase?.patient?.patientRecord;

  return (
    <>
      <Box mb={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>
          <strong>Implants</strong>
        </Typography>

        {editingAllowed && plan.version === PlanVersionType.Version2 ? (
          <ActionButton
            variant={'outlined'}
            onClick={() => setShowImplantEditorDialog(true)}
            disabled={disabled || loadingPlanImplants}
          >
            <Typography color={'secondary'} variant={'button'} noWrap={true}>
              Edit Implants
            </Typography>
          </ActionButton>
        ) : null}

        {editingAllowed && plan.version !== PlanVersionType.Version2 ? (
          <ActionButton
            variant={'outlined'}
            onClick={() => setOpenEditPlanImplantsDialog(true)}
            disabled={loadingPlanImplants}
          >
            <Typography color={'secondary'} variant={'button'} noWrap={true}>
              Edit Implants
            </Typography>
          </ActionButton>
        ) : null}
      </Box>
      <Box
        sx={{
          backgroundColor: 'white',
          border: '1px solid #eaeaea',
          borderRadius: '5px',
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 1,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant={'body1'} noWrap>
                  <strong>LEVEL</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant={'body1'} noWrap>
                  <strong>PART</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>AP</strong>
                </Typography>
              </TableCell>
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>ML</strong>
                </Typography>
              </TableCell>
              {hasImplantsWithScrews ? (
                <TableCell align={'center'}>
                  <Typography variant={'body1'} noWrap>
                    <strong>SCREW SIZE</strong>
                  </Typography>
                </TableCell>
              ) : null}
              {hasFeatureFlag?.(FeatureFlag.implantManagementEnabled) ? (
                <TableCell align={'center'}>
                  <Typography variant={'body1'} noWrap>
                    <strong>VERSIONS</strong>
                  </Typography>
                </TableCell>
              ) : null}
              {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) ? (
                <TableCell align={'center'}>
                  <Typography variant={'body1'} noWrap>
                    <strong>ANGLES</strong>
                  </Typography>
                </TableCell>
              ) : null}
              <TableCell align={'center'}>
                <Typography variant={'body1'} noWrap>
                  <strong>METADATA</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {Object.entries(planImplantLevels).map(([level, planImplantLevel]) => {
            const partType = planImplantLevel.partType;
            const implantType = caseUtils.getImplantType(partType);
            // const implantPartTypeDetail = findImplantPartDetail({
            //   variables: { implantPartDetailId: planImplantLevel.implantPartDetailId },
            // });

            return (
              <TableBody key={level}>
                <TableRow>
                  <TableCell>{format.formatInterbodyLevel(planImplantLevel.level)}</TableCell>
                  <TableCell>{format.formatPartType(partType)}</TableCell>
                  <TableCell align={'center'}>{planImplantLevel.ap ?? <>&mdash;</>}</TableCell>
                  <TableCell align={'center'}>{planImplantLevel.ml ?? <>&mdash;</>}</TableCell>
                  {hasImplantsWithScrews ? (
                    <TableCell align={'center'}>
                      {planImplantLevel.screwLength ? (
                        format.formatImplantScrewLength(planImplantLevel.screwLength)
                      ) : (
                        <>&mdash;</>
                      )}
                    </TableCell>
                  ) : null}
                  {hasFeatureFlag?.(FeatureFlag.implantManagementEnabled) ? (
                    <TableCell align={'center'}>
                      <Stack></Stack>
                    </TableCell>
                  ) : null}
                  {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) ? (
                    <TableCell align={'center'}>
                      <Stack>
                        {hasPelvisHighPelvicIncidence &&
                        [PartType.ALIF, PartType.ALIF_X_TWO_DOWN].includes(partType) ? (
                          <Typography>
                            Cr-Cd. {planImplantLevel.cranialCaudalThreadAngle ?? <>&mdash;</>}&deg;
                          </Typography>
                        ) : null}
                        {hasPelvisOblique && [ImplantType.LLIF].includes(implantType) ? (
                          <Typography>
                            Obl. {planImplantLevel.obliqueThreadAngle ?? <>&mdash;</>}&deg;
                          </Typography>
                        ) : null}
                        {hasPelvisHighCrest && [ImplantType.LLIF].includes(implantType) ? (
                          <Typography>
                            Cr-Cd. {planImplantLevel.cranialCaudalThreadAngle ?? <>&mdash;</>}&deg;
                          </Typography>
                        ) : null}
                        {![
                          PartType.ALIF,
                          PartType.ALIF_X_TWO_DOWN,
                          PartType.LLIF_LEFT,
                          PartType.LLIF_RIGHT,
                        ].includes(partType) ? (
                          <>&mdash;</>
                        ) : null}
                        {!hasPelvisHighPelvicIncidence &&
                        !hasPelvisOblique &&
                        !hasPelvisHighCrest ? (
                          <>&mdash;</>
                        ) : null}
                      </Stack>
                    </TableCell>
                  ) : null}
                  <TableCell align={'center'}>
                    {planImplantLevel.metadata ? (
                      <Stack justifyContent={'center'}>
                        <NtopMetadataPopover metadata={planImplantLevel.metadata} />
                      </Stack>
                    ) : null}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </Box>
      {openEditPlanImplantsDialog ? (
        <>
          {plan.version === PlanVersionType.Version1 ? (
            <EditPlanV1ImplantsDialog
              activeCase={activeCase}
              planId={plan.planId}
              planImplantLevels={planImplantLevels}
              onClose={async (shouldUpdate) => {
                if (shouldUpdate) {
                  refetch();
                  onUpdated();
                }
                setOpenEditPlanImplantsDialog(false);
              }}
              open={openEditPlanImplantsDialog}
              hasImplantsWithScrews={hasImplantsWithScrews}
            />
          ) : null}
          {plan.version === PlanVersionType.Version3 ? (
            <EditPlanNtopImplantsDialog
              activeCase={activeCase}
              planId={plan.planId}
              planImplantLevels={planImplantLevels}
              onClose={async (shouldUpdate) => {
                if (shouldUpdate) {
                  refetch();
                  onUpdated();
                }
                setOpenEditPlanImplantsDialog(false);
              }}
              open={openEditPlanImplantsDialog}
              hasImplantsWithScrews={hasImplantsWithScrews}
            />
          ) : null}
        </>
      ) : null}
      {showImplantEditorDialog && plan ? (
        <ImplantEditorDialogProvider
          patientRecord={patientRecord}
          plan={plan}
          activeCase={activeCase}
        >
          <ImplantEditorDialog
            open={showImplantEditorDialog}
            plan={plan}
            activeCase={activeCase}
            onClose={(shouldUpdate) => {
              setShowImplantEditorDialog(false);
              if (shouldUpdate) {
                onUpdated();
              }
            }}
          />
        </ImplantEditorDialogProvider>
      ) : null}
    </>
  );
}
