import * as Yup from 'yup';
import { ConfigurationKeyType } from '../enums';

export const configurationFreeAgentSchema = Yup.object().shape({
  accessTokenUrl: Yup.string()
    .url('Must be a valid URL')
    .required('Field: accessTokenUrl is required'),
  graphQLUrl: Yup.string().url('Must be a valid URL').required('Field: graphQLUrl is required'),
  clientId: Yup.string().required('Field: clientId is required'),
  clientSecret: Yup.string().required('Field: clientSecret is required'),
  disabled: Yup.boolean().required('Field: disabled is required'),
  taskDisabled: Yup.boolean().required('Field: taskDisabled is required'),
});

export const configurationEmailSchema = Yup.object().shape({
  noReplyAddress: Yup.string().required('Field: noReplyAddress is required'),
  clientUrl: Yup.string().required('Field: clientUrl is required'),
  disabled: Yup.boolean().required('Field: disabled is required'),
  smsOriginationNumber: Yup.string().required('Field: smsOriginationNumber is required'),
  supportEmail: Yup.string().email().required('Field: supportEmail is required'),
  verificationCodeTimeout: Yup.number().required('Field: verificationCodeTimeout is required'),
  notificationsDisabled: Yup.boolean().required('Field: notificationsDisabled is required'),
});

export const configurationOnShapeSchema = Yup.object().shape({
  apiUrl: Yup.string().url('Must be a valid URL').required('Field: apiUrl is required'),
  accessKey: Yup.string().required('Field: accessKey is required'),
  secretKey: Yup.string().required('Field: secretKey is required'),
  cacheDisabled: Yup.boolean().required('Field: cacheDisabled is required'),
});

export const configurationOneSignalSchema = Yup.object().shape({
  apiKey: Yup.string().required('Field: apiKey is required'),
  apiId: Yup.string().required('Field: apiId is required'),
  notificationDisabled: Yup.boolean().required('Field: notificationDisabled is required'),
});

export const configurationGreenlightGuruSchema = Yup.object().shape({
  exportApiUrl: Yup.string().required('Field: exportApiUrl is required'),
  apiKey: Yup.string().required('Field: apiKey is required'),
  cacheDisabled: Yup.boolean().required('Field: cacheDisabled is required'),
});

export const configurationDaisySchema = Yup.object().shape({
  awsAccessKeyId: Yup.string(),
  awsSecretAccessKey: Yup.string(),
  awsS3Bucket: Yup.string().required('Field: awsS3Bucket is required'),
  awsDaisyListenerLambdaUrl: Yup.string()
    .url('Must be a valid URL')
    .required('Field: awsDaisyListenerLambdaUrl is required'),
  awsStepFunctionLambdaUrl: Yup.string()
    .url('Must be a valid URL')
    .required('Field: awsStepFunctionLambdaUrl is required'),
  awsStepFunctionAccessKeyId: Yup.string().required(
    'Field: awsStepFunctionAccessKeyId is required',
  ),
  awsStepFunctionSecretAccessKey: Yup.string().required(
    'Field: awsStepFunctionSecretAccessKey is required',
  ),
  awsStepFunctionS3Bucket: Yup.string().required('Field: awsStepFunctionS3Bucket is required'),
  awsStepFunctionRegion: Yup.string().required('Field: awsStepFunctionRegion is required'),
  disabled: Yup.boolean().required('Field: disabled is required'),
});

export const configurationCloudDesignSchema = Yup.object().shape({
  awsAccessKeyId: Yup.string().required('Field: awsAccessKeyId is required'),
  awsSecretAccessKey: Yup.string().required('Field: awsSecretAccessKey is required'),
  awsS3Bucket: Yup.string().required('Field: awsS3Bucket is required'),
  awsSqsQueueUrl: Yup.string()
    .url('Must be a valid URL')
    .required('Field: awsSqsQueueUrl is required'),
  disabled: Yup.boolean().required('Field: disabled is required'),
});

export const configurationAwsS3AssetsBucketSchema = Yup.object().shape({
  awsAccessKeyId: Yup.string().required('Field: awsAccessKeyId is required'),
  awsSecretAccessKey: Yup.string().required('Field: awsSecretAccessKey is required'),
  awsRegion: Yup.string().required('Field: awsRegion is required'),
  awsS3Bucket: Yup.string().required('Field: awsS3Bucket is required'),
});

export const configurationAwsSimpleEmailServiceSchema = Yup.object().shape({
  username: Yup.string().required('Field: username is required'),
  server: Yup.string().required('Field: server is required'),
  port: Yup.number().required('Field: port is required'),
  region: Yup.string().required('Field: region is required'),
  password: Yup.string().required('Field: password is required'),
});

export const configurationAwsSimpleNotificationServiceSchema = Yup.object().shape({
  region: Yup.string().required('Field: region is required'),
  username: Yup.string().required('Field: username is required'),
  password: Yup.string().required('Field: password is required'),
});

export const configurationEasyPostSchema = Yup.object().shape({
  apiKey: Yup.string().required('Field: apiKey is required'),
  apiTestKey: Yup.string().required('Field: apiTestKey is required'),
  webhookSecret: Yup.string().required('Field: webhookSecret is required'),
  disabled: Yup.boolean().required('Field: disabled is required'),
});

// TODO: Update with appropriate type instead of any
export const configurationSchema = Yup.lazy((value: ConfigurationKeyType): any => {
  switch (value) {
    case ConfigurationKeyType.FreeAgent:
      return Yup.object().shape({
        accessTokenUrl: Yup.string()
          .url('Must be a valid URL')
          .required('Field: accessTokenUrl is required'),
        graphQLUrl: Yup.string()
          .url('Must be a valid URL')
          .required('Field: graphQLUrl is required'),
        clientId: Yup.string().required('Field: clientId is required'),
        clientSecret: Yup.string().required('Field: clientSecret is required'),
        disabled: Yup.boolean().required('Field: disabled is required'),
        taskDisabled: Yup.boolean().required('Field: taskDisabled is required'),
      });
    case ConfigurationKeyType.Email:
      return Yup.object().shape({
        noReplyAddress: Yup.string().required('Field: noReplyAddress is required'),
        clientUrl: Yup.string().url('Must be a valid URL').required('Field: clientUrl is required'),
        disabled: Yup.boolean().required('Field: disabled is required'),
        notificationsDisabled: Yup.boolean().required('Field: disabled is required'),
      });
    case ConfigurationKeyType.OnShape:
      return Yup.object().shape({
        apiUrl: Yup.string().url('Must be a valid URL').required('Field: apiUrl is required'),
        accessKey: Yup.string().required('Field: accessKey is required'),
        secretKey: Yup.string().required('Field: secretKey is required'),
        cacheDisabled: Yup.boolean().required('Field: cacheDisabled is required'),
      });
    case ConfigurationKeyType.OneSignal:
      return Yup.object().shape({
        apiKey: Yup.string().required('Field: apiKey is required'),
        apiId: Yup.string().required('Field: apiId is required'),
        notificationDisabled: Yup.boolean().required('Field: notificationDisabled is required'),
      });
    case ConfigurationKeyType.Daisy:
      return Yup.object().shape({
        awsAccessKeyId: Yup.string().required('Field: awsAccessKeyId is required'),
        awsSecretAccessKey: Yup.string().required('Field: awsSecretAccessKey is required'),
        awsS3Bucket: Yup.string().required('Field: awsS3Bucket is required'),
        awsStepFunctionAccessKeyId: Yup.string().required(
          'Field: awsStepFunctionAccessKeyId is required',
        ),
        awsStepFunctionSecretAccessKey: Yup.string().required(
          'Field: awsStepFunctionSecretAccessKey is required',
        ),
        awsStepFunctionRegion: Yup.string().required('Field: awsStepFunctionRegion is required'),
        disabled: Yup.boolean().required('Field: disabled is required'),
      });
    case ConfigurationKeyType.CloudDesign:
      return Yup.object().shape({
        awsAccessKeyId: Yup.string().required('Field: awsAccessKeyId is required'),
        awsSecretAccessKey: Yup.string().required('Field: awsSecretAccessKey is required'),
        awsS3Bucket: Yup.string().required('Field: awsS3Bucket is required'),
        awsSqsQueueUrl: Yup.string()
          .url('Must be a valid URL')
          .required('Field: awsSqsQueueUrl is required'),
        disabled: Yup.boolean().required('Field: disabled is required'),
      });
    case ConfigurationKeyType.AwsSimpleEmailService:
      return Yup.object().shape({
        server: Yup.string().required('Field: server is required'),
        port: Yup.number().required('Field: port is required'),
        region: Yup.string().required('Field: region is required'),
        password: Yup.string().required('Field: password is required'),
      });
    case ConfigurationKeyType.EasyPost:
      return Yup.object().shape({
        apiKey: Yup.string().required('Field: apiKey is required'),
        webhookSecret: Yup.string().required('Field: webhookSecret is required'),
        disabled: Yup.boolean().required('Field: disabled is required'),
      });
    default:
      return Yup.object();
  }
});

export const combinedSchema = Yup.object().shape({
  freeAgent: configurationFreeAgentSchema,
  email: configurationEmailSchema,
  onShape: configurationOnShapeSchema,
  oneSignal: configurationOneSignalSchema,
  daisy: configurationDaisySchema,
  cloudDesign: configurationCloudDesignSchema,
  awsSimpleEmailService: configurationAwsSimpleEmailServiceSchema,
  easyPost: configurationEasyPostSchema,
});
