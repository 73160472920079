import { ICaseReportRevision } from '@workflow-nx/common';
import { sortBy } from 'lodash';
import { CaseReportRevisionType } from '../../views/cases/CaseView/CasePlanningTab/CaseReportDialog/CaseReportDialog.reducer';

export const getCaseReportRevisions = (
  caseReportRevisions: ICaseReportRevision[],
): CaseReportRevisionType[] => {
  if (caseReportRevisions.length === 0) {
    return [{ revision: 0, description: 'Initial Release' }];
  }

  const revisions = caseReportRevisions.map((caseReportRevision) => ({
    revision: caseReportRevision.revision,
    description: caseReportRevision.description,
  }));

  return sortBy(revisions, ['revision']);
};
